import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Select,
  Typography,
  MenuItem,
  Modal,
  Paper,
  InputBase,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import Buttons from "./button";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 500,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "260px",
  height: "35px",
  backgroundColor: "#fff",
  borderRadius: "20px",
  "&::placeholder": {
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  width: "260px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const complaintCategory = [
  "None",
  "No Signal",
  "E48-32 No Signal",
  "Searching for Signal",
  "Adapter Issue",
  "Remote Broken",
  "Tv Not Working",
  "Set Top Box - Not Working",
  "Cable Wire/Pin Damage",
  "HDMI Cable Issue",
  "AV Card Issue",
];

const renderData = (custHeader, custValue) => {
  return (
    <Typography sx={{ display: "flex", gap: "10px", fontWeight: "bolder" }}>
      <Typography sx={{ width: "120px" }}>{custHeader} </Typography>:
      <Typography>{custValue}</Typography>
    </Typography>
  );
};

const CreateComplaints = ({ openModal, handleCloseModal, userData }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showBox, setShowBox] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const inputRefDate = useRef(null);
  const inputRefCategory = useRef(null);
  const inputRefRemarks = useRef(null);
  const inputRefAssign = useRef(null);

  const handleAddComplaint = async () => {
    const compdate = inputRefDate.current.value;
    const category = inputRefCategory.current.value;
    const remarks = inputRefRemarks.current.value;
    let assigned = inputRefAssign.current.value;
    if (assigned === "none") {
      assigned = "";
    }

    try {
      const response = await axios.post(
        "/createcomplaints",
        {
          date: compdate,
          msg: category,
          remarks: remarks,
          assigned: assigned,
          caf_no: filteredData.caf_no,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("Complaint added");
        setShowBox(false);
        setSearch("");
        handleCloseModal();
      }
    } catch (error) {
      toast.error("Error adding Complaint");
      console.log("Error adding Complaint", error);
    }
  };
  const handleSearchFilters = (e) => {
    e.preventDefault();

    let filterSearch = [];
    if (!search) {
      filterSearch = [];
    } else {
      const searchTerm = search.toLowerCase();
      filterSearch = userData.customers.filter((customer) =>
        Object.values(customer).some((value) => {
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(searchTerm);
        })
      );
    }

    setFilteredData(filterSearch[0]);
    setShowBox(true);
  };

  useEffect(() => {
    if (userData) {
      setData(userData);
    }
  }, [userData]);

  useEffect(() => {
    setShowBox(false);
  }, [openModal]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", color: "#fbbd2c" }}
        >
          Add Complaints
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "20px",
              width: "max-content",
            }}
          >
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 300,
                backgroundColor: "#fbbd2c",
                borderRadius: "30px",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                placeholder="Caf No/Name/Mobile"
                inputProps={{ "aria-label": "Search" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button type="submit" onClick={handleSearchFilters}>
                <SearchIcon sx={{ color: "#000" }} />
              </Button>
            </Paper>
          </Box>

          {showBox && filteredData && (
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                border: "2px solid #fbbd2c",
                borderRadius: "10px",
                padding: "10px",
                flexWrap: "wrap",
                width: "max-content",
                color: "#fff",
                flexDirection: "column",
                fontSize: "18px",
              }}
            >
              {renderData(
                `Customer Name`,
                filteredData && filteredData.customer_name
              )}
              {renderData(
                `Caf No`,
                filteredData && filteredData.caf_no.toUpperCase()
              )}
              {renderData(`Area`, filteredData && filteredData.area)}
              {renderData(`Mobile`, filteredData && filteredData.mobile)}
            </Box>
          )}
        </Box>
        {/* Add Complaints */}
        {showBox && filteredData && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginTop: "10px",
                flexWrap: "wrap",
                maxWidth: "100%",
                justifyContent: "center",
              }}
            >
              {/* Date */}

              <StyledInputBase
                style={{
                  width: "260px",
                  backgroundColor: "#fff",
                  margin: "10px 0px",
                  borderRadius: "20px",
                }}
                placeholder="Customer Name"
                type="date"
                inputProps={{
                  ref: inputRefDate,
                  defaultValue: new Date().toISOString().slice(0, 10),
                }}
              />

              {/* Category */}

              <StyledSelect
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  ref: inputRefCategory,
                }}
                defaultValue={"None"}
              >
                {complaintCategory.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </StyledSelect>

              {/* Remarks */}

              <StyledInputBase
                style={{
                  maxWidth: "260px",
                  backgroundColor: "#fff",
                  margin: "10px 0px",
                  borderRadius: "20px",
                }}
                placeholder="Remarks"
                type="text"
                inputProps={{ ref: inputRefRemarks }}
              />
              <StyledSelect
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  ref: inputRefAssign,
                }}
                defaultValue={"none"}
              >
                <MenuItem value={"none"}>None</MenuItem>
                {userData.users &&
                  userData.users.map((name) => (
                    <MenuItem key={name._id} value={name.name}>
                      {name.name}
                    </MenuItem>
                  ))}
              </StyledSelect>
            </Box>
            {/* <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                fontWeight: "bold",
                width: "300px",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                },
              }}
              onClick={handleAddComplaint}
            >
              Add
            </Button> */}
            <Buttons
              clickHandler={handleAddComplaint}
              width="300px"
              content="Add"
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CreateComplaints;
