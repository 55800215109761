import React, { useState, useRef, useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Icon,
  InputBase,
  InputLabel,
  styled,
  Button,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import MessageStatus from "../../components/modals/messagestatus";
import AuthContext from "../../context/authContext";
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};
const MessageReports = () => {
  const { userData } = useContext(AuthContext);
  const [reports, setreports] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [search, setSearch] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [openMessageStatus, setOpenMessageStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const inputRefFromDate = useRef(null);
  const inputRefToDate = useRef(null);

  const handleopenMessageStatus = () => setOpenMessageStatus(true);
  const handlecloseMessageStatus = () => setOpenMessageStatus(false);

  const handleMessageStatus = (id) => {
    const checkidStatus = userData.cable_messages.filter(
      (stat) => stat._id === id
    );
    setStatusData(checkidStatus[0].sent_to);
    handleopenMessageStatus();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGetPayments = async () => {
    const fromdate = inputRefFromDate.current.value;
    const todate = inputRefToDate.current.value;

    // Filter payments based on date range from the filtered data
    const filteredPayments = userData.cable_messages.filter((payment) => {
      const paymentDate = new Date(payment.date).toISOString().split("T")[0];
      return paymentDate >= fromdate && paymentDate <= todate;
    });

    // Set the filtered data to state
    setreports(filteredPayments);
    setSearch(filteredPayments);
    setShowTable(true);
  };
  const handleSearch = (text) => {
    const searchFilter = search;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setreports(searchFilter);
      return;
    }

    const filteredReports = searchFilter.filter((customer) =>
      Object.values(customer).some((value) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(text.toLowerCase());
      })
    );

    setreports(filteredReports);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        margin: { xs: "5px 10px", sm: "10px 40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {/* From Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>From</InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefFromDate }}
            />
          </Box>
          {/* To Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>To Date</InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefToDate }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Search</InputLabel>

            <StyledInputBase
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
          <br />
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "start" }}>
            <Button
              sx={{
                color: "#000",
                backgroundColor: "#fbbd2c",
                "&:hover": {
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                },
                marginTop: "20px",
              }}
              onClick={handleGetPayments}
            >
              View
            </Button>
          </Box>
        </Box>
      </Box>
      {showTable ? (
        <>
          {/* Table */}
          <Box sx={{ marginTop: "20px", overflow: "scroll" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                  <TableCell sx={{ color: "#000" }}>S.No</TableCell>
                  <TableCell sx={{ color: "#000" }}>Date</TableCell>
                  <TableCell sx={{ color: "#000" }}>Template</TableCell>
                  <TableCell sx={{ color: "#000" }}>Sent</TableCell>
                  <TableCell sx={{ color: "#000" }}>Success</TableCell>
                  <TableCell sx={{ color: "#000" }}>Failed</TableCell>
                  <TableCell sx={{ color: "#000" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell sx={{ color: "#fff" }}>{index + 1}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.date.split("T")[0]}
                      </TableCell>

                      <TableCell sx={{ color: "#fff" }}>
                        {item.cable_msg_temp}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {item.sent_to.length}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {
                          item.sent_to.filter((suc) => suc.status === "success")
                            .length
                        }
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {
                          item.sent_to.filter((fai) => fai.status !== "success")
                            .length
                        }
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        <Link>
                          <IconButton
                            sx={{
                              color: "#fbbd2c",
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#000",
                              },
                            }}
                          >
                            <Icon>
                              <VisibilityIcon
                                onClick={() => handleMessageStatus(item._id)}
                              />
                            </Icon>
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          {/* Pagination */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#fbbd2c",
                "& .MuiSvgIcon-root": {
                  // Targeting all SVG icons
                  fill: "#fbbd2c", // Arrow color
                },
              }}
            />
          </Box>
        </>
      ) : (
        ""
      )}
      <MessageStatus
        openMessageStatus={openMessageStatus}
        handlecloseMessageStatus={handlecloseMessageStatus}
        statusData={statusData}
      />
    </Box>
  );
};

export default MessageReports;
