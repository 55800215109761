import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Modal, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomerPay from "./customerPay";

const customerViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400, lg: 600 },
  height: 580,
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  color: "#fff",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const renderData = (custHeader, custValue) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          width: "120px",
        }}
      >
        {custHeader}
      </Typography>
      :
      <Typography
        sx={{
          width: "150px",
        }}
      >
        {custValue}
      </Typography>
    </Box>
  );
};

const ViewCustomerModal = ({
  openCustomerViewModal,
  handleCloseCustomerView,
  custViewData,
}) => {
  const [openPay, setOpenPay] = useState(false);

  const [value, setValue] = useState(2);
  const [data, setData] = useState(custViewData && custViewData[0]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => {
    setOpenPay(false);
    handleCloseCustomerView();
  };

  useEffect(() => {
    if (custViewData) {
      setData(custViewData[0]);
    }
  }, [custViewData]);

  return (
    <Modal
      open={openCustomerViewModal}
      onClose={handleCloseCustomerView}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={customerViewStyle}>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bolder",
            fontSize: "20px",
            color: "#fbbd2c",
          }}
        >
          Customer Details
        </Typography>
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "100%",
              overflow: "auto",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              centered
              sx={{
                "& .Mui-selected": {
                  color: "#fbbd2c",
                  fontWeight: "bold",
                  fontSize: "18px",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#fbbd2c",
                  color: "#fbbd2c",
                },
                overflow: "scroll",
              }}
            >
              <Tab
                label="Basic"
                {...a11yProps(0)}
                sx={{
                  color: "#fbbd2c",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
              <Tab
                label="Address"
                {...a11yProps(1)}
                sx={{
                  color: "#fbbd2c",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
              <Tab
                label="STB"
                {...a11yProps(2)}
                sx={{
                  color: "#fbbd2c",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              />
            </Tabs>
          </Box>
          {/* Basic */}
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  gap: "10px",
                }}
              >
                {renderData("Customer Name", data && data.customer_name)}
                {renderData("Caf No", data && data.caf_no.toUpperCase())}
                {renderData("Stb No", data && data.stb_no)}

                {renderData("Gender", data && data.gender)}
                {renderData("Dob", data && data.dob && data.dob.split("T")[0])}
                {renderData("Customer Status", data && data.customer_status)}
                {renderData("Payment Status", data && data.payment_status)}
                {renderData(
                  "Register Date",
                  data && data.registerdate.split("T")[0]
                )}
                {renderData("Area", data && data.area)}
                {renderData("Mobile", data && data.mobile)}
                {renderData("Alternate Mobile", data && data.alternate_mobile)}
                {renderData("Email ID", data && data.email_id)}
              </Box>
            </Box>
          </CustomTabPanel>
          {/* Address */}
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  gap: "10px",
                }}
              >
                {renderData("Address", data && data.address)}
                {renderData("Pincode", data && data.pincode)}
                {renderData("House Type", data && data.house_type)}
                {renderData("Building Type", data && data.building_type)}
                {renderData("Lat/Long", data && (data.lat, data.long))}
                {renderData("ID Type", data && data.proof_type)}
                {renderData("ID No", data && data.proof_no)}
                {renderData("Address Type", data && data.address_proof_type)}
                {renderData("Address No", data && data.address_proof_no)}
                {renderData("Remarks", data && data.remarks)}

                {data && data.lat && data.long && (
                  <Box sx={{ textAlign: "center" }}>
                    <Link
                      to={`https://www.google.com/maps/@${data && data.lat},${
                        data && data.long
                      }`}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#fbbd2c",
                          color: "#000",
                          fontWeight: "bold",
                          width: "300px",
                          "&:hover": {
                            backgroundColor: "#fbbd2c",
                            color: "#000",
                          },
                        }}
                      >
                        Find Location
                      </Button>
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
          </CustomTabPanel>
          {/* Cable */}
          <CustomTabPanel value={value} index={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  gap: "10px",
                }}
              >
                {renderData("STB No", data && data.stb_no)}
                {renderData("STB Type", data && data.stb_type)}
                {renderData("STB Provider", data && data.stb_provider)}
                {renderData("Plan", data && data.cable_plan)}
                {renderData(
                  "Register Date",
                  data && data.registerdate.split("T")[0]
                )}

                {renderData(`Installation`, data && data.cable_installation)}
                {renderData("Deposit", data && data.cable_deposit)}
                {renderData("Discount", data && data.cable_discount)}
                {renderData("Balance", data && data.cable_balance)}
                {renderData(
                  "Last Pay Date",
                  data && data.payments.length > 0
                    ? data.payments[data.payments.length - 1].pay_date
                    : "New"
                )}
                <Button
                  sx={{
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                    fontWeight: "bold",
                    width: "300px",
                    "&:hover": {
                      backgroundColor: "#fbbd2c",
                      color: "#000",
                    },
                  }}
                  onClick={handleOpenPay}
                >
                  Pay
                </Button>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>
        <CustomerPay
          openPay={openPay}
          handleClosePay={handleClosePay}
          custData={custViewData[0]}
        />
      </Box>
    </Modal>
  );
};

export default ViewCustomerModal;
