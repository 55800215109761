import React from "react";
import { Button, Typography } from "@mui/material";
const Buttons = ({ content, clickHandler, icon, width }) => {
  return (
    <Button
      sx={{
        color: "#000",
        backgroundColor: "#fbbd2c",
        display: "flex",
        width: { width },
        alignItems: "center",
        gap: "5px",
        "&:hover": {
          backgroundColor: "#fbbd2c",
        },
      }}
      onClick={clickHandler}
    >
      {icon}

      <Typography
        sx={{
          fontWeight: "bolder",
          display: { xs: "none", sm: "flex" },
        }}
      >
        {content}
      </Typography>
    </Button>
  );
};

export default Buttons;
