import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grow,
  List,
  ListItemButton,
  Modal,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, Link } from "react-router-dom";
import { useAnimation } from "framer-motion";
import { getRoles } from "../../context/RequireAuth";
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  border: "2px solid #fbbd2c",
  boxShadow: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const SidebarModal = ({ openSidebarModal, handleCloseSideModal }) => {
  const location = useLocation();
  const locate = location.pathname;
  const [role, setRole] = useState("");

  const [openCustomers, setOpenCustomers] = useState(
    locate === "/dashbaord/customers/cable" ||
      locate === "/dashbaord/customers/internet"
      ? true
      : false
  );
  const [openPackages, setOpenPackages] = useState(
    locate === "dasshboard/packages/plan" ||
      locate === "dasshboard/packages/area"
      ? true
      : false
  );
  const [openExpenses, setOpenExpenses] = useState(
    locate === "dashboard/expenses/add" || locate === "dashboard/expenses/view"
      ? true
      : false
  );
  const [openReports, setOpenReports] = useState(
    locate === "dashboard/reports/collections" ||
      locate === "dashboard/reports/customers" ||
      locate === "dashboard/reports/history"
      ? true
      : false
  );
  const [openTrash, setOpenTrash] = useState(
    locate === "dashboard/trash/customers" ||
      locate === "dashboard/trash/payments"
      ? true
      : false
  );
  const [openMessages, setOpenMessages] = useState(
    locate === "dashboard/messages/cable" ||
      locate === "dashboard/messages/internet"
      ? true
      : false
  );

  const handleClickCustomers = () => {
    setOpenCustomers(!openCustomers);
  };
  const handleClickPackages = () => {
    setOpenPackages(!openPackages);
  };
  const handleClickExpenses = () => {
    setOpenExpenses(!openExpenses);
  };
  const handleClickReports = () => {
    setOpenReports(!openReports);
  };
  const handleClickTrash = () => {
    setOpenTrash(!openTrash);
  };
  const handleClickMessages = () => {
    setOpenMessages(!openMessages);
  };

  useEffect(() => {}, [locate]);
  useEffect(() => {
    setRole(getRoles());
  }, []);

  const controls = useAnimation();

  const startGlowAnimation = () => {
    controls.start({
      boxShadow: [
        "0 0 5px #FFD700, 0 0 10px #FFD700, 0 0 15px #FFD700",
        "0 0 10px #FFD700, 0 0 15px #FFD700, 0 0 20px #FFD700",
        "0 0 20px #FFD700, 0 0 15px #FFD700, 0 0 10px #FFD700",
        "0 0 15px #FFD700, 0 0 10px #FFD700, 0 0 5px #FFD700",
      ],
      transition: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 5, // Adjust the duration as needed
      },
    });
  };
  useEffect(() => {
    startGlowAnimation();
  }, []);
  return (
    <Modal
      open={openSidebarModal}
      onClose={handleCloseSideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            backgroundImage: "linear-gradient(to bottom, #0f0c29,#302b63)",
            width: "200px",
            padding: "10px",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <List
              sx={{
                width: "80%",
                color: "#fff",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
              component="nav"
            >
              <Link
                to="/dashboard"
                underline="none"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setOpenCustomers(false);
                  setOpenExpenses(false);
                  setOpenPackages(false);
                  setOpenReports(false);
                  setOpenTrash(false);
                  setOpenMessages(false);
                  handleCloseSideModal();
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography
                    sx={{ color: locate === "/dashboard" ? "#fdbb2d" : "#fff" }}
                  >
                    Dashboard
                  </Typography>
                </ListItemButton>
              </Link>
              <Link
                to="customerlist"
                underline="none"
                sx={{
                  color: "#fff",
                  display: { xs: "block", sm: "none", md: "none", lg: "none" },
                }}
                onClick={() => {
                  setOpenCustomers(false);
                  setOpenExpenses(false);
                  setOpenPackages(false);
                  setOpenReports(false);
                  setOpenTrash(false);
                  setOpenMessages(false);
                  handleCloseSideModal();
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography
                    sx={{
                      color:
                        locate === "/dashboard/customerlist"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  >
                    Customers
                  </Typography>
                </ListItemButton>
              </Link>
              <Link
                to="paymentlist"
                underline="none"
                sx={{
                  color: "#fff",
                  display: { xs: "block", sm: "none", md: "none", lg: "none" },
                }}
                onClick={() => {
                  setOpenCustomers(false);
                  setOpenExpenses(false);
                  setOpenPackages(false);
                  setOpenReports(false);
                  setOpenTrash(false);
                  setOpenMessages(false);
                  handleCloseSideModal();
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography
                    sx={{
                      color:
                        locate === "/dashboard/paymentlist"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  >
                    Payments
                  </Typography>
                </ListItemButton>
              </Link>

              {role === "client" && (
                <Link
                  to="complaints"
                  underline="none"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    setOpenCustomers(false);
                    setOpenExpenses(false);
                    setOpenPackages(false);
                    setOpenReports(false);
                    setOpenTrash(false);
                    setOpenMessages(false);
                    handleCloseSideModal();
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    {/* <ListItemText primary="Dashboard" /> */}
                    <Typography
                      sx={{
                        color:
                          locate === "/dashboard/complaints"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    >
                      Complaints
                    </Typography>
                  </ListItemButton>
                </Link>
              )}

              {role === "client" && (
                <ListItemButton
                  checked={openTrash}
                  onClick={() => {
                    setOpenCustomers(false);
                    handleClickTrash();
                    setOpenPackages(false);
                    setOpenReports(false);
                    setOpenExpenses(false);
                    setOpenMessages(false);
                  }}
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  {/* <ListItemText primary="Customers" /> */}
                  <Typography
                    sx={{
                      color:
                        locate === "/dashboard/trash/customers" ||
                        locate === "/dashboard/trash/payments"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  >
                    Trash
                  </Typography>

                  {openTrash ? (
                    <ExpandLess
                      sx={{
                        position: "absolute",
                        right: "5px",
                        color:
                          locate === "/dashboard/trash/customers" ||
                          locate === "/dashboard/trash/payments"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        position: "absolute",
                        right: "5px",
                        color:
                          locate === "/dashboard/trash/customers" ||
                          locate === "/dashboard/trash/payments"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    />
                  )}
                </ListItemButton>
              )}

              {role === "client" && (
                <Collapse in={openTrash} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Grow
                      in={openTrash}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openTrash ? { timeout: 1000 } : {})}
                    >
                      <Link
                        to="trash/customers"
                        underline="none"
                        sx={{ color: "#fff" }}
                        onClick={handleCloseSideModal}
                      >
                        <ListItemButton>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#FFB946" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/trash/customers"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Customers
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>

                    <Grow
                      in={openTrash}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openTrash ? { timeout: 1300 } : {})}
                    >
                      <Link
                        to="trash/payments"
                        underline="none"
                        sx={{ color: "#fff" }}
                        onClick={handleCloseSideModal}
                      >
                        <ListItemButton>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#F7685B" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/trash/payments"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Payments
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                  </List>
                </Collapse>
              )}
              {role === "client" && (
                <ListItemButton
                  onClick={() => {
                    setOpenCustomers(false);
                    handleClickReports();
                    setOpenExpenses(false);
                    setOpenTrash(false);
                  }}
                  sx={{
                    borderRadius: "10px",
                  }}
                >
                  {/* <ListItemText primary="Customers" /> */}
                  <Typography
                    sx={{
                      color:
                        locate === "/dashboard/reports/customers" ||
                        locate === "/dashboard/reports/collections" ||
                        locate === "/dashboard/reports/history" ||
                        locate === "/dashboard/reports/messages"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  >
                    Reports
                  </Typography>

                  {openReports ? (
                    <ExpandLess
                      sx={{
                        position: "absolute",
                        right: "5px",
                        color:
                          locate === "/dashboard/reports/customers" ||
                          locate === "/dashboard/reports/collections" ||
                          locate === "/dashboard/reports/history" ||
                          locate === "/dashboard/reports/messages"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        position: "absolute",
                        right: "5px",
                        color:
                          locate === "/dashboard/reports/customers" ||
                          locate === "/dashboard/reports/collections" ||
                          locate === "/dashboard/reports/history" ||
                          locate === "/dashboard/reports/messages"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    />
                  )}
                </ListItemButton>
              )}

              {role === "client" && (
                <Collapse in={openReports} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Grow
                      in={openReports}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openReports ? { timeout: 1000 } : {})}
                    >
                      <Link
                        to="reports/collections"
                        underline="none"
                        sx={{ color: "#fff" }}
                      >
                        <ListItemButton onClick={() => handleCloseSideModal()}>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#FFB946" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/reports/collections"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Collections
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                    <Grow
                      in={openReports}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openReports ? { timeout: 1300 } : {})}
                    >
                      <Link
                        to="reports/customers"
                        underline="none"
                        sx={{ color: "#fff" }}
                      >
                        <ListItemButton onClick={() => handleCloseSideModal()}>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#F7685B" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/reports/customers"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Customers
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                    <Grow
                      in={openReports}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openReports ? { timeout: 1600 } : {})}
                    >
                      <Link
                        to="reports/history"
                        underline="none"
                        sx={{ color: "#fff" }}
                      >
                        <ListItemButton onClick={() => handleCloseSideModal()}>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#2ED47A" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/reports/history"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            History
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                    <Grow
                      in={openReports}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openReports ? { timeout: 1600 } : {})}
                    >
                      <Link
                        to="reports/messages"
                        underline="none"
                        sx={{ color: "#fff" }}
                      >
                        <ListItemButton onClick={() => handleCloseSideModal()}>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#F78D1E" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/reports/messages"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Messages
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                    <Grow
                      in={openReports}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(openReports ? { timeout: 1600 } : {})}
                    >
                      <Link
                        to="reports/logs"
                        underline="none"
                        sx={{ color: "#fff" }}
                      >
                        <ListItemButton onClick={() => handleCloseSideModal()}>
                          <div
                            className="listicon_color"
                            style={{ borderColor: "#F78D1E" }}
                          ></div>
                          <Typography
                            sx={{
                              color:
                                locate === "/dashboard/reports/logs"
                                  ? "#fdbb2d"
                                  : "#fff",
                            }}
                          >
                            Logs
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </Grow>
                  </List>
                </Collapse>
              )}
              {role === "client" && (
                <Link
                  to="quickreport"
                  underline="none"
                  sx={{ color: "#fff" }}
                  onClick={() => {
                    setOpenCustomers(false);
                    setOpenExpenses(false);
                    setOpenReports(false);
                    setOpenTrash(false);
                    handleCloseSideModal();
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <ListItemText primary="Dashboard" /> */}
                    <Typography
                      sx={{
                        color:
                          locate === "/dashboard/quickreport"
                            ? "#fdbb2d"
                            : "#fff",
                      }}
                    >
                      Quick Report
                    </Typography>
                  </ListItemButton>
                </Link>
              )}
              <Link
                to="maps"
                underline="none"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setOpenCustomers(false);
                  setOpenExpenses(false);
                  setOpenPackages(false);
                  setOpenReports(false);
                  setOpenTrash(false);
                  setOpenMessages(false);
                  handleCloseSideModal();
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography
                    sx={{
                      color: locate === "/dashboard/maps" ? "#fdbb2d" : "#fff",
                    }}
                  >
                    Maps
                  </Typography>
                </ListItemButton>
              </Link>
              {role === "client" && (
                <Link to="/dashboard/messages">
                  <ListItemButton
                    onClick={() => {
                      setOpenCustomers(false);
                      setOpenReports(false);
                      setOpenExpenses(false);
                      setOpenTrash(false);
                      handleCloseSideModal();
                    }}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    {/* <ListItemText primary="Customers" /> */}
                    <Typography
                      sx={{
                        color:
                          locate === "/dashboard/messages" ? "#fdbb2d" : "#fff",
                      }}
                    >
                      Messages
                    </Typography>
                  </ListItemButton>
                </Link>
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SidebarModal;
