import { Box } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <h1>404</h1>
        <h2>Page not Found</h2>
        <a href="/">click here to login page</a>
      </Box>
    </Box>
  );
};

export default NotFound;
