import React, { useEffect, useState, useContext } from "react";
import { Box, Icon, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { BarChart, axisClasses } from "@mui/x-charts";
import axios from "../api/axios";
import { getRoles, user_name } from "../context/RequireAuth";
import AuthContext from "../context/authContext";
import { PieChart } from "@mui/x-charts/PieChart";
const chartSetting = {
  width: 540,
  height: 440,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
      fill: "#fff",
    },
    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
      stroke: "#fff",
      fill: "#fff",
      strokeWidth: 1,
    },
    // leftAxis Line Styles
    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
      stroke: "#fff",
      fill: "#fff",
      strokeWidth: 1,
    },
    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
      strokeWidth: "1",
      fill: "#fff",
    },

    // change bottom label styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
      strokeWidth: "1",
      fill: "#fff",
    },
  },
};
const dataset = [
  {
    cable: 21000,
    internet: 13000,
    month: "Jan",
  },
  {
    cable: 31000,
    internet: 19000,
    month: "Feb",
  },
  {
    cable: 28000,
    internet: 15000,
    month: "Mar",
  },
  {
    cable: 34000,
    internet: 21000,
    month: "Apr",
  },
  {
    cable: 11000,
    internet: 4000,
    month: "May",
  },
  {
    cable: 14000,
    internet: 7000,
    month: "June",
  },
];

const valueFormatter = (value) => `${value}Rs`;
const NewDashboard = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [role, setRole] = useState("");
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [totalUserPaymentAmount, setTotalUserPaymentAmount] = useState(0);

  // User Data Fetch
  useEffect(() => {
    setRefresh(true);
  }, []);
  useEffect(() => {
    if (userData) {
      const clientData = userData;
      const currentuser = user_name();

      const allusers = clientData?.users?.filter(
        (user) => user.username.toLowerCase() === currentuser.toLowerCase()
      );

      // client total amount check
      let totalAmount = 0;
      let today = new Date().toISOString();
      const todayDate = today.split("T")[0];
      const filtrdcust = clientData?.customers?.filter(
        (ite) => ite.customer_status === "Active"
      );
      const payments = filtrdcust?.forEach((pays) =>
        pays.payments.forEach((custpaid) => {
          if (custpaid.pay_date === todayDate) {
            totalAmount += custpaid.amount;
          }
        })
      );
      setTotalPaymentAmount(totalAmount);

      // If client found, calculate total payment amount
      if (allusers <= 0) {
        let totalUserAmount = 0;
        const filtrdcust = clientData?.customers?.filter(
          (ite) => ite.customer_status === "Active"
        );
        const payments = filtrdcust.forEach((pays) =>
          pays.payments.forEach((custpaid) => {
            if (
              custpaid.pay_date === todayDate &&
              custpaid.collected_by.toLowerCase() ===
                clientData.name.toLowerCase()
            ) {
              totalUserAmount += custpaid.amount;
            }
          })
        );
        // console.log(totalAmount);
        setTotalUserPaymentAmount(totalUserAmount);
      } else {
        let totalUserAmount = 0;
        const filtrdcust = clientData?.customers?.filter(
          (ite) => ite.customer_status === "Active"
        );
        const payments = filtrdcust?.forEach((pays) =>
          pays.payments.forEach((custpaid) => {
            if (
              custpaid.pay_date === todayDate &&
              custpaid.collected_by.toLowerCase() ===
                allusers[0].name.toLowerCase()
            ) {
              totalUserAmount += custpaid.amount;
            }
          })
        );
        // console.log(totalAmount);
        setTotalUserPaymentAmount(totalUserAmount);
      }
    }
  }, [userData]);
  useEffect(() => {
    setRole(getRoles());
  }, []);
  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "20px",
    //     minHeight: "100vh",
    //     padding: { xs: "5px 10px", lg: "15px 40px" },
    //     flexWrap: "wrap",
    //     alignItems: "center",
    //     marginTop: "20px",
    //   }}
    // >

    // </Box>
    <Box sx={{ minHeight: "100vh" }}>
      {/* All Charts */}
      <Box
        sx={{
          display: "flex",
          padding: "15px",
          flexDirection: "column",
          gap: "5px",
          justifyContent: "space-around",
        }}
      >
        {/* Top Charts */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {/* Customers */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "20px",
              border: "2px solid #fDbb2c",
              width: "max-content",
              borderRadius: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                color: "#fDbb2c",
                fontSize: "50px",
                opacity: ".5",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box>
              <Typography sx={{ color: "#fdbb2c", fontSize: "20px" }}>
                Customers
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {
                  userData?.customers?.filter(
                    (cust) => cust.customer_status === "Active"
                  ).length
                }
              </Typography>
            </Box>
          </Box>
          {/* Payments */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "20px",
              border: "2px solid #fDbb2c",
              width: "max-content",
              borderRadius: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                color: "#fDbb2c",
                fontSize: "50px",
                opacity: ".5",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box>
              <Typography sx={{ color: "#fdbb2c", fontSize: "20px" }}>
                Payments
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {totalPaymentAmount}
              </Typography>
            </Box>
          </Box>
          {/* Purchase */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "20px",
              border: "2px solid #fDbb2c",
              width: "max-content",
              borderRadius: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                color: "#fDbb2c",
                fontSize: "50px",
                opacity: ".5",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box>
              <Typography sx={{ color: "#fdbb2c", fontSize: "20px" }}>
                Purchase
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {totalPaymentAmount}
              </Typography>
            </Box>
          </Box>
          {/* Expenses */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "20px",
              border: "2px solid #fDbb2c",
              width: "max-content",
              borderRadius: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                color: "#fDbb2c",
                fontSize: "50px",
                opacity: ".5",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box>
              <Typography sx={{ color: "#fdbb2c", fontSize: "20px" }}>
                Expenses
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {totalPaymentAmount}
              </Typography>
            </Box>
          </Box>
          {/* Complaints */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "20px",
              border: "2px solid #fDbb2c",
              width: "max-content",
              borderRadius: "15px",
            }}
          >
            <GroupsIcon
              sx={{
                color: "#fDbb2c",
                fontSize: "50px",
                opacity: ".5",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box>
              <Typography sx={{ color: "#fdbb2c", fontSize: "20px" }}>
                Complaints
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {
                  userData?.customers?.filter(
                    (cust) => cust.customer_status === "Active"
                  ).length
                }
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Middle Charts */}
        <Box
          sx={{
            padding: "20px 10px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* bar Chart */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flex: 1,
              width: "100%",
            }}
          >
            <BarChart
              dataset={dataset}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "month",
                  categoryGapRatio: 0.5,
                  barGapRatio: 0.05,
                },
              ]}
              series={[
                {
                  dataKey: "cable",
                  valueFormatter,
                  color: "#FDBB2C",
                },
                {
                  dataKey: "internet",
                  valueFormatter,
                  color: "#30D479",
                },
              ]}
              {...chartSetting}
            />
          </Box>
          {/* Statistics */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              flexWrap: "wrap",
              gap: "20px",
              flex: 1,
              alignItems: "center",
            }}
          >
            {/* Customers */}
            <Box
              sx={{
                padding: "10px 20px",
                display: "flex",
                border: "2px solid #fDbb2c",
                width: { xs: "100%", md: "40%" },
                // width: {xs:"80vw",sm:"max-content"},
                borderRadius: "15px",
                flexDirection: "column",
                height: "max-content",
              }}
            >
              <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
                Customer Statistics
              </Typography>
              {/* Total Customers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Customers
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {userData?.customers?.length}
                </Typography>
              </Box>
              {/* Active Customers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Active Customers
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {
                    userData?.customers?.filter(
                      (cust) => cust.customer_status === "Active"
                    ).length
                  }
                </Typography>
              </Box>
              {/* Registered Today */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Registered Today
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
              {/* Active Customers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Registered this Month
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
            </Box>
            {/* Payments */}
            <Box
              sx={{
                padding: "10px 20px",
                display: "flex",
                border: "2px solid #fDbb2c",
                width: { xs: "100%", md: "40%" },
                borderRadius: "15px",
                flexDirection: "column",
                height: "max-content",
              }}
            >
              <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
                Payment Statistics
              </Typography>
              {/* Total Payments */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Payments
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {userData?.customers?.length}
                </Typography>
              </Box>
              {/* Total Balance */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Balance
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {
                    userData?.customers?.filter(
                      (cust) => cust.customer_status === "Active"
                    ).length
                  }
                </Typography>
              </Box>
              {/* Payments Today */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Payments Today
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
              {/* Payments this Month */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Payments this Month
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
            </Box>
            {/* Expenses */}
            <Box
              sx={{
                padding: "10px 20px",
                display: "flex",
                border: "2px solid #fDbb2c",
                width: { xs: "100%", md: "40%" },
                borderRadius: "15px",
                flexDirection: "column",
                height: "max-content",
              }}
            >
              <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
                Expense Statistics
              </Typography>
              {/* Total Expenses */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Expenses
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {userData?.customers?.length}
                </Typography>
              </Box>
              {/* Total Purchase */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Purchase
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {
                    userData?.customers?.filter(
                      (cust) => cust.customer_status === "Active"
                    ).length
                  }
                </Typography>
              </Box>
              {/* Payments this month */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Payments this Month
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
              {/* Expenses this Month */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Expenses this Month
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
            </Box>
            {/* Complaints */}
            <Box
              sx={{
                padding: "10px 20px",
                display: "flex",
                border: "2px solid #fDbb2c",
                width: { xs: "100%", md: "40%" },
                borderRadius: "15px",
                flexDirection: "column",
                height: "max-content",
              }}
            >
              <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
                Complaint Statistics
              </Typography>
              {/* Total Complaints */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Complaints
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {userData?.customers?.length}
                </Typography>
              </Box>
              {/* Total Pending */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Total Pending Complaints
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {
                    userData?.customers?.filter(
                      (cust) => cust.customer_status === "Active"
                    ).length
                  }
                </Typography>
              </Box>
              {/* Complaints Today */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Complaints Today
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
              {/* Complaints this Month */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Complaints this Month
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  0
                </Typography>
              </Box>
            </Box>
            {/* Miscellaneous */}
            <Box
              sx={{
                padding: "10px 20px",
                display: "flex",
                border: "2px solid #fDbb2c",
                gap: { xs: "0px", sm: "20px" },
                borderRadius: "15px",
                height: "max-content",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {/* <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
                Miscellaneous Statistics
              </Typography> */}
              {/* Box 1 */}
              <Box>
                {/* Total Complaints */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    Total Users
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    {userData?.customers?.length}
                  </Typography>
                </Box>
                {/* Total Pending */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    Total Messages Pending
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    {
                      userData?.customers?.filter(
                        (cust) => cust.customer_status === "Active"
                      ).length
                    }
                  </Typography>
                </Box>
              </Box>
              {/* Box 2 */}
              <Box>
                {/* Complaints Today */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    No. of Providers
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    {userData?.mso_isp?.length}
                  </Typography>
                </Box>
                {/* Complaints this Month */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    Complaints this Month
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    0
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewDashboard;
