import {
  Box,
  Button,
  Typography,
  Paper,
  InputBase,
  Select,
  MenuItem,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import AddCustomerModal from "../../components/modals/addcustomer";
import ViewCustomerModal from "../../components/modals/viewcustomermodal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TablePagination from "@mui/material/TablePagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Buttons from "../../components/modals/button";
import AuthContext from "../../context/authContext";
import { useNavigate } from "react-router-dom";

const columns = [
  "S.No",
  "Name",
  "CAF No",
  "Area",
  "STB No",
  "Mobile",
  "Type",
  "Plan",
  "Balance",
  "Last Paid",
  "Status",
  "Payments",
  "Actions",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#fff",
      backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
    },
  },
};

const CableCustomer = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [area, setArea] = useState("All");
  const [payType, setPayType] = useState("All");
  const [editData, setEditData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openCustomerViewModal, setOpenCustomerViewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const [custViewData, setCustViewData] = useState([]);

  const handleOpenCustomer = () => {
    setOpenCustomerModal(true);

    setEditMode(false);
  };
  const handleCloseCustomer = () => {
    setOpenCustomerModal(false);
    setEditMode(false);
    setEditData([]);
    setRefresh(true);
  };

  const handleOpenEditCustomer = (id) => {
    const filterEdit = filteredData.filter((item) => item._id === id);
    setEditData(filterEdit);
    setOpenCustomerModal(true);
    setEditMode(true);
  };

  const handleOpenCustomerView = () => setOpenCustomerViewModal(true);
  const handleCloseCustomerView = () => {
    setOpenCustomerViewModal(false);
    setArea("All");
    setPayType("All");
    setRefresh(true);
  };

  const handleFilters = () => {
    // Apply area filter
    let filtered =
      userData.customers &&
      userData.customers.filter(
        (filt) =>
          filt.customer_status !== "trash" && filt.customer_status !== "Erased"
      );
    let filteredDataByArea = [];
    if (area === "All") {
      filteredDataByArea = filtered;
    } else {
      filteredDataByArea = filtered.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Apply payment status filter
    let filteredDataByPaymentStatus = [];
    if (payType !== "All") {
      filteredDataByPaymentStatus = filteredDataByArea.filter(
        (customer) => customer.payment_status === payType
      );
    } else {
      filteredDataByPaymentStatus = filteredDataByArea;
    }

    let filterSearch = [];
    if (!search) {
      filterSearch = filteredDataByPaymentStatus;
    } else {
      const searchTerm = search.toLowerCase();
      filterSearch = filteredDataByPaymentStatus.filter((customer) =>
        Object.entries(customer).some(([key, value]) => {
          if (key === "remarks") return false; // Exclude "Remarks" field
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(searchTerm);
        })
      );
    }

    setFilteredData(filterSearch);
  };
  useEffect(() => {
    handleFilters();
  }, [area, search, payType]);

  useEffect(() => {
    const filters = userData?.customers?.filter(
      (cust) =>
        cust.customer_status !== "Erased" && cust.customer_status !== "trash"
    );

    setFilteredData(filters);
  }, [userData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteCustomer = async (id) => {
    try {
      const res = await axios.put(
        "/deletecust",
        { id },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      if (res.status === 200) {
        toast.success("Customer Deleted");
        setRefresh(true);
        handleFilters();
      } else {
        toast.error("Please try again");
      }
    } catch (error) {
      console.log("Error deleting Customer", error);
    }
  };
  const handleviewCustomer = (id) => {
    const filters =
      userData.customers &&
      userData.customers.filter((item) => item._id === id);
    setCustViewData(filters);
    handleOpenCustomerView();
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      theme: "striped",
      styles: {
        textColor: [0, 0, 0], // White text color for headers
        fontSize: 5, // Font size for the text
        cellPadding: 2, // Padding for each cell
        valign: "middle", // Vertical alignment for all cells
        halign: "center", // Horizontal alignment for all cells
      },
      headStyles: {
        fillColor: [66, 133, 244], // Blue fill color for headers
        textColor: [255, 255, 255],
        fontStyle: "bold", // Bold font style for headers
      },
      bodyStyles: {
        fillColor: false, // No fill color for body
      },
      columnStyles: {
        0: { cellWidth: "auto" }, // Adjust column width automatically
      },
      margin: { top: 20 }, // Set margin for the PDF
    });
    doc.save("customers.pdf");
  };

  return (
    <Box
      sx={{
        display: "flex",
        color: "#fff",
        margin: "20px",
        minHeight: "100vh",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Top Box */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "85vw",
          flexWrap: "wrap-reverse",
          gap: "20px",
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "row", sm: "column" },
          }}
        >
          {/* Add Customer */}
          <Box>
            <Button
              sx={{
                color: "#000",
                backgroundColor: "#fbbd2c",
                width: { xs: "max-content", sm: "180px" },
                display: "flex",
                alignItems: "center",
                gap: "10px",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                },
              }}
              onClick={handleOpenCustomer}
            >
              <PersonAddIcon sx={{ fontSize: "20px" }} />

              <Typography
                sx={{
                  fontWeight: "bolder",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                Add Customer
              </Typography>
            </Button>
          </Box>
          {/* Downloads PDF/EXCEL */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <DownloadTableExcel
              filename="Customers"
              sheet="Customers"
              currentTableRef={tableRef.current}
            >
              {/* <Button
                sx={{
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                  display: "flex",
                  width: "80px",
                  alignItems: "center",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                  },
                }}
              >
                <DescriptionIcon sx={{ fontSize: "20px" }} />
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  Excel
                </Typography>
              </Button> */}
              <Buttons
                content="Excel"
                icon={<DescriptionIcon sx={{ fontSize: "20px" }} />}
              />
            </DownloadTableExcel>
            {/* <Button
              sx={{
                color: "#000",
                backgroundColor: "#fbbd2c",
                width: "80px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                },
              }}
              onClick={downloadPDF}
            >
              <PictureAsPdfIcon sx={{ fontSize: "20px" }} />
              <Typography
                sx={{
                  fontWeight: "bolder",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                Pdf
              </Typography>
            </Button> */}
            <Buttons
              content="Pdf"
              clickHandler={downloadPDF}
              icon={<PictureAsPdfIcon sx={{ fontSize: "20px" }} />}
            />
          </Box>
        </Box>
        {/* Right Panel */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* Filter Buttons */}
          <Box
            sx={{
              display: "flex",
              width: 250,
              justifyContent: "space-between",
            }}
          >
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              defaultValue={"All"}
              value={area}
              onChange={(e) => setArea(e.target.value)}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              sx={{
                backgroundColor: "#fbbd2c",
                width: "100px",
                height: "50px",
                borderColor: "#fbbd2c",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {userData &&
                userData.areas &&
                userData.areas.map((item) => (
                  <MenuItem key={item.area_name} value={item.area_name}>
                    {item.area_name}
                  </MenuItem>
                ))}
            </Select>

            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={payType}
              onChange={(e) => setPayType(e.target.value)}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              sx={{
                backgroundColor: "#fbbd2c",
                width: "100px",
                height: "50px",
                borderColor: "#fbbd2c",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Paid"}>Paid</MenuItem>
              <MenuItem value={"Unpaid"}>UnPaid</MenuItem>
            </Select>
          </Box>

          {/* Search Button */}
          <Box>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: 260,
                backgroundColor: "#fbbd2c",
                borderRadius: "30px",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                inputProps={{ "aria-label": "Search" }}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
      {/* Table */}
      <Box sx={{ overflow: "scroll" }}>
        <Table sx={{ border: "none" }} ref={tableRef}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
              {columns.map((item) => (
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  key={item}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData
                .sort((a, b) => {
                  // Convert CAF No to uppercase for consistent sorting
                  const cafA = a.caf_no.toUpperCase();
                  const cafB = b.caf_no.toUpperCase();
                  if (cafA < cafB) {
                    return -1;
                  }
                  if (cafA > cafB) {
                    return 1;
                  }
                  return 0;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell sx={{ color: "#fff" }}>
                      {" "}
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {item.customer_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.caf_no.toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {item.area}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {item.stb_no}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {item.mobile}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.stb_type}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.cable_plan}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: item.cable_balance <= 0 ? "#fff" : "red",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.cable_balance}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {(item.payments.length > 0 &&
                        item.payments.slice().reverse()[0].pay_date) ||
                        "New"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          item.customer_status === "Active" ? "#30D479" : "red",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.customer_status}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          item.payment_status === "Paid" ? "#30D479" : "red",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.payment_status}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        // display: "flex",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        sx={{
                          color: "#fbbd2c",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                        onClick={() => handleviewCustomer(item._id)}
                      >
                        <Icon>
                          <VisibilityIcon />
                        </Icon>
                      </IconButton>

                      <IconButton
                        sx={{
                          color: "#fbbd2c",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                        onClick={() => handleOpenEditCustomer(item._id)}
                      >
                        <Icon>
                          <EditIcon />
                        </Icon>
                      </IconButton>

                      <IconButton
                        sx={{
                          color: "#fbbd2c",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                        onClick={() => handleDeleteCustomer(item.caf_no)}
                      >
                        <Icon>
                          <DeleteIcon />
                        </Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Box>
      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 100, 500, 1000]}
          component="div"
          count={(filteredData && filteredData.length) || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#fbbd2c",
            "& .MuiSvgIcon-root": {
              // Targeting all SVG icons
              fill: "#fbbd2c", // Arrow color
            },
          }}
        />
      </Box>
      <AddCustomerModal
        openCustomerModal={openCustomerModal}
        handleCloseCustomer={handleCloseCustomer}
        editData={editData}
        editMode={editMode}
      />
      <ViewCustomerModal
        openCustomerViewModal={openCustomerViewModal}
        handleCloseCustomerView={handleCloseCustomerView}
        custViewData={custViewData}
      />
    </Box>
  );
};

export default CableCustomer;
