import {
  Box,
  Typography,
  InputBase,
  Button,
  InputLabel,
  styled,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const Plan = () => {
  const inputRefPlanName = useRef(null);
  const inputRefPlanAmount = useRef(null);
  const inputRefNewAmount = useRef(null);
  const inputRefType = useRef(null);
  const inputRefTypeMSO = useRef(null);

  return (
    <Box sx={{ height: "100vh", padding: { xs: "5px 10px", sm: "15px 40px" } }}>
      <Typography
        sx={{ textAlign: "center", fontSize: "20px", color: "#fbbd2c" }}
      >
        Add Plan
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          marginTop: "30px",
        }}
      >
        {/* Plan Name */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Plan Name</InputLabel>
          <StyledInputBase
            style={{
              width: "200px",
              backgroundColor: "#fff",
              margin: "10px 0px",
              borderRadius: "20px",
            }}
            placeholder="Plan Name"
            type="text"
            inputProps={{ ref: inputRefPlanName }}
          />
        </Box>
        {/* Plan Amount */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Plan Amount</InputLabel>
          <StyledInputBase
            style={{
              width: "200px",
              backgroundColor: "#fff",
              margin: "10px 0px",
              borderRadius: "20px",
            }}
            placeholder="Plan Amount"
            type="text"
            inputProps={{ ref: inputRefPlanAmount }}
          />
        </Box>
        {/* Type cable/Internet */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Type of Report</InputLabel>
          <StyledSelect
            displayEmpty
            defaultValue="cable"
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefType,
            }}
          >
            <MenuItem value={"cable"}>Cable</MenuItem>
            <MenuItem value={"internet"}>Internet</MenuItem>
          </StyledSelect>
        </Box>
        {/* Report Type */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>MSO/ISP</InputLabel>
          <StyledSelect
            displayEmpty
            defaultValue="scv"
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefTypeMSO,
            }}
          >
            <MenuItem value={"scv"}>SCV</MenuItem>
            <MenuItem value={"tccl"}>TCCL</MenuItem>
          </StyledSelect>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{
              backgroundColor: "#fbbd2c",
              color: "#000",
              "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
            }}
          >
            <AddIcon /> Add Plan
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
              <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                S.No
              </TableCell>
              <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                MSO Name
              </TableCell>
              <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                Plan Name
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Plan Amount
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Customer Count
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Convert
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>1</TableCell>
              <TableCell sx={{ color: "#fff" }}>Cable - SCV</TableCell>

              <TableCell sx={{ color: "#fff" }}>Tamil Silver</TableCell>
              <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                260
              </TableCell>
              <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                235
              </TableCell>
              <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                <InputBase
                  sx={{
                    ml: 2,
                    flex: 1,
                    color: "#000",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "0px 10px",
                    width: "80px",
                  }}
                  placeholder="New Plan Amount"
                  inputProps={{
                    "aria-label": "Search",
                    ref: inputRefNewAmount,
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  gap: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                <Button
                  sx={{
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                    margin: "0px 10px",
                    "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
                  }}
                >
                  {" "}
                  Convert
                </Button>
                <Button
                  sx={{
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                    margin: "0px 10px",
                    "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
                  }}
                >
                  {" "}
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Plan;
