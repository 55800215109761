import {
  Box,
  Button,
  Typography,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import React, { useState, useContext } from "react";
import CustomerPay from "../components/modals/customerPay";
import toast from "react-hot-toast";
import AuthContext from "../context/authContext";

const Payments = () => {
  const { userData } = useContext(AuthContext);
  const [openPay, setOpenPay] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [searchCafNo, setSearchCafNo] = useState("");

  const handleClearSearchField = () => {
    setSearchCafNo("");
  };
  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => {
    setOpenPay(false);
    handleClearSearchField();
    setCustomerData([]);
  };

  const handleSearchCafNo = (e, selectedValue) => {
    if (selectedValue !== "" || selectedValue !== null) {
      e.preventDefault();

      if (!selectedValue) {
        toast.error("Kindly fill CafNo");
        return;
      }
      let searchcaf = selectedValue.toLowerCase();
      let searchcaf1 = searchcaf.split(" ")[0];

      const filteredUsers = userData.customers.filter(
        (cust) => cust.caf_no.toLowerCase() === searchcaf1
      );
      if (filteredUsers.length > 0) {
        filteredUsers[0].payments.reverse();
        setCustomerData(filteredUsers);
      } else {
        setCustomerData([]);
        toast.error("No Customers Found");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        marginTop: "40px",
        borderRadius: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
            defaultValue={null}
            disableClearable
            onChange={(e, value) => {
              setSearchCafNo(value);
              handleSearchCafNo(e, value);
            }}
            value={searchCafNo}
            freeSolo
            options={
              (userData &&
                userData.customers &&
                userData.customers
                  .filter((item) => item.customer_status === "Active")
                  .map(
                    (option) =>
                      option.caf_no.toUpperCase() +
                      " " +
                      option.mobile +
                      " " +
                      option.customer_name
                  )) ||
              []
            }
            filterOptions={(options, { inputValue }) =>
              options
                .filter((option) =>
                  option.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 7)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Stack>
      </Box>

      {customerData &&
        customerData.map((item) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: { xs: "0px", md: "60px 30px" },
              gap: { xs: "10px", md: "50px" },
              justifyContent: "center",
              overflow: "auto",
            }}
            key={item._id}
          >
            <Box
              sx={{
                color: "#fff",
                backgroundColor: "#24243e",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "20px",
                border: "2px solid #fbbd2c",
                borderRadius: "20px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Name</Typography>:
                  <Typography>{item.customer_name}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Customer ID</Typography>:
                  <Typography>{item.caf_no.toUpperCase()}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>STB No</Typography>:
                  <Typography sx={{ fontWeight: "bolder" }}>
                    {item.stb_no}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Mobile</Typography>:
                  <Typography>{item.mobile}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Register Date</Typography>
                  :<Typography>{item.registerdate.split("T")[0]}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Last Pay Date</Typography>
                  :
                  <Typography>
                    {item.payments[0] ? item.payments[0].pay_date : "New"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Balance</Typography>:
                  <Typography>{item.cable_balance}</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Amount</Typography>:
                  <Typography>{item.cable_plan}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ width: "120px" }}>Status</Typography>:
                  <Typography
                    sx={{
                      color: item.payment_status === "Paid" ? "#30D479" : "red",
                      fontSize: "bold",
                    }}
                  >
                    {item.payment_status}
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                  },
                }}
                onClick={handleOpenPay}
              >
                Pay
              </Button>
            </Box>
          </Box>
        ))}

      <CustomerPay
        openPay={openPay}
        handleClosePay={handleClosePay}
        custData={customerData[0]}
      />
    </Box>
  );
};

export default Payments;
