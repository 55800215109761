import React, { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import Dashboard from "./dashboard/dashboard";
import CableCustomer from "./dashboard/customers/cable";
import Payments from "./dashboard/payments";
import Complaints from "./dashboard/complaints";
import Plan from "./dashboard/packages/plan";
import DesignLayout from "./dashboard/dashLayout";
import Area from "./dashboard/packages/area";
import Purchase from "./dashboard/expenses/purchase";
import Office from "./dashboard/expenses/office";
import DayBook from "./dashboard/daybook";
import TrashCustomers from "./dashboard/trash/trashcustomers";
import TrashPayments from "./dashboard/trash/trashpayments";
import Maps from "./dashboard/maps";
import CollectionsReport from "./dashboard/reports/collections";
import CustomerReport from "./dashboard/reports/customers";
import History from "./dashboard/reports/history";
import Messages from "./dashboard/messages";
import PaymentList from "./dashboard/paymentList";
import Settings from "./dashboard/settings";
import Account from "./dashboard/account";
import { Toaster } from "react-hot-toast";
import NotFound from "./components/modals/404";
import RequireAuth from "./context/RequireAuth";
import MessageReports from "./dashboard/reports/messageReport";
import CustomerList from "./dashboard/customerList";
import Logs from "./dashboard/reports/logs";
import ErrorBoundary from "./error";
import LoadingSpinner from "./LoadingSpinner";
import NewDashboard from "./dashboard/newdashboard";
import QuickReport from "./dashboard/quickreport";
// import axios from "./api/axios";

// axios.interceptors.response.use((item) => console.log(item));

const App = () => {
  return (
    <>
      <Suspense
        fallback={
          <p style={{ fontSize: "23px", textAlign: "center" }}>
            Loading user details...
          </p>
        }
      >
        <Routes>
          <Route index element={<Login />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route element={<RequireAuth allowedRoles={["client", "admin"]} />}>
            <Route path="dashboard" element={<DesignLayout />}>
              <Route path="customers" element={<CableCustomer />} />
              <Route path="payments" element={<Payments />} />
              <Route path="packages/plan" element={<Plan />} />
              <Route path="packages/area" element={<Area />} />
              <Route path="expenses/purchase" element={<Purchase />} />
              <Route path="expenses/office" element={<Office />} />
              <Route path="daybook" element={<DayBook />} />
              <Route path="trash/customers" element={<TrashCustomers />} />
              <Route path="trash/payments" element={<TrashPayments />} />
              <Route
                path="reports/collections"
                element={<CollectionsReport />}
              />
              <Route path="reports/customers" element={<CustomerReport />} />
              <Route path="reports/history" element={<History />} />
              <Route path="reports/messages" element={<MessageReports />} />
              <Route path="reports/logs" element={<Logs />} />
              <Route path="messages" element={<Messages />} />
              <Route path="settings" element={<Settings />} />
              <Route path="account" element={<Account />} />
              <Route path="quickreport" element={<QuickReport />} />
            </Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["client", "user", "admin"]} />}
          >
            <Route path="dashboard" element={<DesignLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="complaints" element={<Complaints />} />
              <Route path="maps" element={<Maps />} />
              <Route path="paymentlist" element={<PaymentList />} />
              <Route path="customerlist" element={<CustomerList />} />
              <Route path="newdash" element={<NewDashboard />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster position="top-right" />
      </Suspense>
    </>
  );
};

export default App;
