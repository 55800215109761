import React, { useEffect, useRef, useState, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getRoles, user_name } from "../context/RequireAuth";
// Explicitly import Leaflet's default marker icon assets
import unpaid from "../assets/unpaid.png";
import paid from "../assets/paid.png";
import currentlocation from "../assets/currentlocation.png";
import NavigationIcon from "@mui/icons-material/Navigation";
import {
  Box,
  MenuItem,
  InputLabel,
  styled,
  Select,
  Button,
  Typography,
} from "@mui/material";
import AuthContext from "../context/authContext";

// Override Leaflet's default marker icon to use the imported icon assets
let UnpaidIcon = L.icon({
  iconUrl: unpaid,
  iconSize: [25, 25], // Adjust the size as needed
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

let PaidIcon = L.icon({
  iconUrl: paid,
  iconSize: [25, 25], // Adjust the size as needed
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});
let UserIcon = L.icon({
  iconUrl: currentlocation,
  iconSize: [25, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const position = [10.6580982, 77.0264621];

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const Maps = () => {
  const { userData } = useContext(AuthContext);
  const [userLocation, setUserLocation] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [areas, setArea] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const inputRefArea = useRef(null);
  const inputRefStatus = useRef(null);

  const handleGetMaps = (e) => {
    e.preventDefault();
    const filtArea = inputRefArea.current.value;
    const filtStat = inputRefStatus.current.value;
    let filtered = customerData;
    let filteredDataByArea = [];
    if (filtArea === "all") {
      filteredDataByArea = filtered;
    } else {
      filteredDataByArea = filtered.filter((customer) =>
        filtArea.includes(customer.area)
      );
    }
    let filteredDataByPaymentStatus = [];
    if (filtStat !== "all") {
      filteredDataByPaymentStatus = filteredDataByArea.filter(
        (customer) => customer.payment_status === filtStat
      );
    } else {
      filteredDataByPaymentStatus = filteredDataByArea;
    }

    setFilteredData(filteredDataByPaymentStatus);
    setShowMap(true);
  };

  const updateUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (userData?.customers) {
      const activecust = userData.customers.filter(
        (item) => item.customer_status === "Active"
      );
      const userArea = userData?.users?.filter(
        (item) => item.name === user_name()
      );

      if (getRoles() === "user") {
        setArea(userArea[0].area);

        setCustomerData(
          activecust.filter((item) => userArea[0].area.includes(item.area))
        );
      } else {
        setCustomerData(activecust);
        setArea(userData?.areas);
      }
    }
    updateUserLocation();
    const locationInterval = setInterval(updateUserLocation, 10000); // Update location every 10 seconds

    return () => clearInterval(locationInterval);
  }, [userData]);

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        margin: { xs: "5px 10px", sm: "10px 40px" },
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fbbd2c",
            fontWeight: "bolder",
            fontSize: "20px",
          }}
        >
          Maps
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {/* Customer Area */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Area</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="all"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefArea,
              }}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>

              {areas?.map((name) =>
                getRoles() === "user" ? (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ) : (
                  <MenuItem key={name.area_name} value={name.area_name}>
                    {name.area_name}
                  </MenuItem>
                )
              )}
            </StyledSelect>
          </Box>
          {/* Customer Status */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Status</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="all"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefStatus,
              }}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
              <MenuItem value={"Paid"}>Paid</MenuItem>
            </StyledSelect>
          </Box>
          {/* Get Map Button */}
          <Box>
            {" "}
            <Button
              onClick={handleGetMaps}
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                },
              }}
            >
              Get Map
            </Button>
          </Box>
        </Box>
      </Box>
      {showMap ? (
        <MapContainer
          center={position}
          zoom={15}
          style={{ height: "500px", zIndex: 1, marginBottom: "20px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {filteredData &&
            filteredData.map((marker, index) => {
              const latlng = [
                marker.lat ? marker.lat : 0,
                marker.long ? marker.long : 0,
              ];
              const status = marker.payment_status;
              // if (!marker.lat || !marker.long) {
              //   console.warn(`Invalid coordinates for marker: ${marker}`);
              //   return null;
              // }
              return (
                <Marker
                  key={index}
                  position={latlng}
                  icon={status === "Paid" ? PaidIcon : UnpaidIcon}
                >
                  <Popup>
                    Name: {marker.customer_name} <br />
                    Balance: {marker.cable_balance} <br />
                    <Box sx={{ textAlign: "center", margin: "10px" }}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/dir/${marker.lat},${
                          marker.long
                        }/${userLocation && userLocation.lat},${
                          userLocation && userLocation.lng
                        }/@16z`}
                      >
                        <NavigationIcon />
                      </a>
                    </Box>
                  </Popup>
                </Marker>
              );
            })}
          {userLocation && (
            <Marker position={userLocation} icon={UserIcon}>
              <Popup>Your current location</Popup>
            </Marker>
          )}
        </MapContainer>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Maps;
