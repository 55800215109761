import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
} from "@mui/material";
import AuthContext from "../../context/authContext";

const customerViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "300px", sm: 700 },
  height: 500,
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "#fff",
};

const MessageStatus = ({
  openMessageStatus,
  handlecloseMessageStatus,
  statusData,
}) => {
  const [data, setData] = useState([]);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    if (statusData) {
      setData(statusData);
    }
  }, [statusData]);

  return (
    <Modal
      open={openMessageStatus}
      onClose={handlecloseMessageStatus}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={customerViewStyle}>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bolder", color: "#fbbd2c" }}
        >
          Message Status
        </Typography>
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#fbbd2c", textAlign: "center" }}>
                  S.No
                </TableCell>
                <TableCell sx={{ color: "#fbbd2c", textAlign: "center" }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#fbbd2c", textAlign: "center" }}>
                  Caf No
                </TableCell>
                <TableCell sx={{ color: "#fbbd2c", textAlign: "center" }}>
                  Mobile
                </TableCell>
                <TableCell sx={{ color: "#fbbd2c", textAlign: "center" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => {
                const filteredUserData = userData.filter(
                  (custname) => custname.caf_no === item.caf_no
                );
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    {filteredUserData.map((filteredData) => (
                      <React.Fragment key={filteredData.caf_no}>
                        <TableCell sx={{ color: "#fff" }}>
                          {filteredData.customer_name}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {filteredData.caf_no.toUpperCase()}
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          {filteredData.mobile}
                        </TableCell>
                      </React.Fragment>
                    ))}
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.status}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Modal>
  );
};

export default MessageStatus;
