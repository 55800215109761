// AuthContext.js
import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "../api/axios";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // Function to refresh user data
  const refreshUserData = async () => {
    try {
      const response = await axios.get("/userdata", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          lcono: localStorage.getItem("lcono"),
        },
      });
      setUserData(response.data.clientData);

      setRefresh(false);
    } catch (error) {
      console.error("Error refreshing user data:", error);
    }
  };

  useEffect(() => {
    if (refresh) {
      refreshUserData();
      setRefresh(false);
    }
  }, [refresh]);
  useEffect(() => {
    refreshUserData();
  }, []);
  const memoizedUserData = useMemo(() => userData, [userData]);
  return (
    <AuthContext.Provider
      value={{
        userData: memoizedUserData,
        setRefresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
