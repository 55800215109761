import {
  Box,
  Button,
  Select,
  Typography,
  MenuItem,
  Modal,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  InputBase,
  Paper,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState, useContext } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateComplaints from "../components/modals/createcomplaints";
import axios from "../api/axios";
import toast from "react-hot-toast";
import TablePagination from "@mui/material/TablePagination";
import Buttons from "../components/modals/button";
import AuthContext from "../context/authContext";

const columns = [
  "Caf No / No",
  "Date",
  "Name",
  "Area",
  "Category",
  "Assigned",
  "Status",
];
const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  height: 150,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
};

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "150px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const Complaints = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [complaintsData, setComplaintsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [assign, setAssign] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState("");

  const inputRefAssign = useRef(null);
  const inputRefAssignStatus = useRef(null);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);

    setRefresh(true);
  };
  const handleOpenConfirmModal = (cno) => {
    setOpenConfirmModal(true);
    setAssign(cno);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setAssign("none");
    // setRefresh(true);
    setRefresh(true);
  };

  const handleConfirmAssign = async () => {
    const assignedUser = inputRefAssign.current.value;

    if (assignedUser === "none") {
      handleCloseConfirmModal();
      toast.error("Select any user");
    } else {
      try {
        const res = await axios.post(
          "/assigncomp",
          { assignedUser, assign },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: localStorage.getItem("lcono"),
            },
          }
        );
        if (res.status === 200) {
          setAssign("none");
          handleCloseConfirmModal();
          setRefresh(true);
          toast.success("Assigned Successfully");
        }
      } catch (error) {
        toast.error("Error Assigning");
      }
    }
  };

  const handleConfirmStatusModal = async (id) => {
    try {
      const res = await axios.post(
        "/assigncompstatus",
        { compstatus: "Closed", id },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      if (res.status === 200) {
        setRefresh(true);
        toast.success("Complaint Closed Successfully");
      }
    } catch (error) {
      toast.error("Error Closing");
    }
  };

  const handleSearchFilters = () => {
    let filterSearch = [];
    if (!search) {
      filterSearch = userData.complaints;
    } else {
      const searchTerm = search.toLowerCase();
      filterSearch = userData.complaints.filter((customer) =>
        Object.values(customer).some((value) => {
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(searchTerm);
        })
      );
    }
    setComplaintsData(filterSearch);
  };

  useEffect(() => {
    if (userData.complaints) {
      setComplaintsData(userData.complaints.reverse());
    }
  }, [userData]);
  useEffect(() => {
    handleSearchFilters();
  }, [search, userData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        padding: "15px 20px",
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      {/* create Complaints Button */}
      <Box
        sx={{
          textAlign: { xs: "center", lg: "left" },
          overflow: "scroll",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {/* <Button
          sx={{
            backgroundColor: "#fbbd2c",
            color: "#000",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",

            "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
          }}
          onClick={handleOpenModal}
        >
          <AddCircleOutlineIcon sx={{ fontWeight: "bolder" }} />

          <Typography sx={{ fontWeight: "bolder" }}>
            Create Complaint
          </Typography>
        </Button> */}
        <Buttons
          clickHandler={handleOpenModal}
          content="Create Complaint"
          icon={<AddCircleOutlineIcon sx={{ fontWeight: "bolder" }} />}
        />
        {/* Search Button */}
        <Box>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 260,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
          >
            <InputBase
              sx={{
                ml: 2,
                flex: 1,
                color: "#000",
              }}
              placeholder="Caf No"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "Search" }}
            />
          </Paper>
        </Box>
      </Box>

      {/* Complaints Table */}
      <Box
        sx={{
          overflow: "scroll",
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
              {columns.map((item) => (
                <TableCell
                  sx={{
                    color: "#000",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                  key={item}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {complaintsData &&
              complaintsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item._id}>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.caf_no.toUpperCase()} / {item.complaint_no}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.date.split("T")[0]}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {userData &&
                        userData.customers.filter(
                          (names) => names.caf_no === item.caf_no
                        )[0].customer_name}
                    </TableCell>

                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {userData &&
                        userData.customers.filter(
                          (names) => names.caf_no === item.caf_no
                        )[0].area}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.msg}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.assigned ? (
                        item.assigned
                      ) : (
                        <StyledSelect
                          displayEmpty
                          onChange={() =>
                            handleOpenConfirmModal(item.complaint_no)
                          }
                          inputProps={{
                            "aria-label": "Without label",
                            ref: inputRefAssign,
                          }}
                          defaultValue={"none"}
                        >
                          <MenuItem value={"none"}>None</MenuItem>
                          {userData.users &&
                            userData.users.map((name) => (
                              <MenuItem key={name._id} value={name.name}>
                                {name.name}
                              </MenuItem>
                            ))}
                        </StyledSelect>
                      )}
                    </TableCell>

                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.status === "Open" ? (
                        <StyledSelect
                          onChange={(e) => {
                            setStatus(e.target.value);
                            handleConfirmStatusModal(item.complaint_no);
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                            ref: inputRefAssignStatus,
                          }}
                          defaultValue={"Open"}
                        >
                          <MenuItem value={"Open"}>Open</MenuItem>
                          <MenuItem value={"Closed"}>Close</MenuItem>
                        </StyledSelect>
                      ) : (
                        "Closed"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Box>
      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={(complaintsData && complaintsData.length) || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#fbbd2c",
            "& .MuiSvgIcon-root": {
              // Targeting all SVG icons
              fill: "#fbbd2c", // Arrow color
            },
          }}
        />
      </Box>
      <CreateComplaints
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        userData={userData}
      />
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box sx={modalStyles}>
          <Box>
            <Typography>Confirm Assigning?</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",

                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",

                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleConfirmAssign}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Complaints;
