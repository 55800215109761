import React, { useEffect, useState } from "react";
import "./loginstyles.css";
import { Box, Typography, Input } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "./api/axios";

const Login_URL = "/login";

const Login = () => {
  const [username, setUsername] = useState("");
  const [pass, setPassword] = useState("");
  const [lcono, setLcono] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === "" || pass === "" || lcono === "") {
      setError("Kindly fill all credentials");
    } else {
      try {
        const res = await axios.post(Login_URL, {
          username,
          pass,
          lcono,
        });

        if (res.status === 200) {
          console.log("Successfully logged in");

          localStorage.setItem("token", res.data.authTokens);
          localStorage.setItem("lcono", res.data.lcono);
          localStorage.setItem("username", res.data.username);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("user_name", res.data.user_name);
          setUsername("");
          setPassword("");
          setLcono("");
          toast.success("Successfully logged in");
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        }
      } catch (error) {
        setError("Login Error. Please try again", error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    if (success) {
      toast.success(success);
      setSuccess("");
    }
  }, [error, success]);

  return (
    <Box
      className="login_container"
      sx={{ display: "flex", justifyContent: "space-evenly" }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Link
          to="/"
          style={{
            color: "#fff",
            fontSize: "50px",
            fontFamily: "Rasa, serif",
          }}
        >
          MapsApp
        </Link>
      </Box>
      <Box
        className="loginsec"
        sx={{
          width: { sm: "25%", md: "max-content", xs: "50%" },
          padding: "50px",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            textAlign: "center",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Login
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              marginTop: "10%",
              flexDirection: "column",
            }}
          >
            <Input
              sx={{
                color: "#fff",
                width: "200px",
              }}
              required
              type="text"
              color="warning"
              variant="outlined"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Input
              sx={{
                color: "#fff",
                width: "200px",
              }}
              required
              type="password"
              color="warning"
              variant="outlined"
              placeholder="Password"
              value={pass}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Input
              sx={{
                color: "#fff",
                width: "200px",
                "& input[type=number]": {
                  /* Hide arrows for number input */
                  MozAppearance: "textfield", // Updated here
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none", // Updated here
                      margin: 0,
                    },
                  appearance: "none",
                },
              }}
              required
              type="number"
              color="warning"
              variant="outlined"
              placeholder="LCO No"
              value={lcono}
              onChange={(e) => setLcono(e.target.value)}
            />

            <button
              className="login_submitbtn"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Box>
        </Box>
      </Box>
      <Box>
        {/* sx={{ position: "fixed", bottom: "15px", textAlign: "center" }} */}
        <Typography>Powered by QC Technologies</Typography>
      </Box>
    </Box>
  );
};

export default Login;
