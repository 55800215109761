import React, { useState } from "react";
import Navbar from "../components/navbar/navbar";
import Sidebar from "../components/sidebar/sidebar";
import { Stack, Box, Button } from "@mui/material";
import { Outlet } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SidebarModal from "../components/modals/sidebarmodal";
import "./dashlayoutStyles.css";

const DesignLayout = () => {
  const [openSidebarModal, setOpenSidebarModal] = useState(false);
  const handleOpenSideModal = () => setOpenSidebarModal(true);
  const handleCloseSideModal = () => setOpenSidebarModal(false);

  return (
    <Box sx={{ display: "flex" }} className="custom-scrollbar">
      <Sidebar />
      <Stack sx={{ width: "88%" }}>
        <Navbar />
        <Box
          sx={{
            minHeight: "90vh",
            backgroundColor: "#302b63",
            width: { xs: "100vw", md: "100vw", lg: "100%" },
          }}
        >
          <Outlet />
        </Box>
      </Stack>

      {/* <Button
        sx={{
          display: { xs: "block", sm: "block", md: "flex", lg: "none" },
          color: "#000",
          position: "fixed",
          borderRadius: "50px",
          bottom: "10px",
          right: "10px",
          width: "40px",
          height: "60px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fbbd2c",
          "&:hover": {
            backgroundColor: "#fbbd2c",
            color: "#000",
          },
          zIndex: 2,
        }}
        onClick={handleOpenSideModal}
      >
        <MenuOpenIcon sx={{ fontSize: "40px" }} />
      </Button>
      <SidebarModal
        openSidebarModal={openSidebarModal}
        handleCloseSideModal={handleCloseSideModal}
      /> */}
    </Box>
  );
};

export default DesignLayout;
