import {
  Box,
  Typography,
  styled,
  MenuItem,
  Select,
  InputLabel,
  InputBase,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TablePagination from "@mui/material/TablePagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AuthContext from "../../context/authContext";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "150px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const columns = [
  "S.No",
  "Name",
  "CAF No",
  "Area",
  "Mobile",
  "STB No",
  "Payment Date",
  "Payments",
  "Collected By",
];

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const Collections = () => {
  const { userData } = useContext(AuthContext);
  const tableRef = useRef();
  const [reports, setreports] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [search, setSearch] = useState([]);
  const inputRefArea = useRef("");
  const [inpUser, setInpUser] = useState("All");
  const inputRefFromDate = useRef(null);
  const inputRefToDate = useRef(null);

  const handleGetPayments = async () => {
    const fromdate = inputRefFromDate.current.value;
    const todate = inputRefToDate.current.value;
    const area = inputRefArea.current.value;

    // Filter data by area
    let filteredDataByArea = [];

    if (area === "All") {
      filteredDataByArea = userData.customers;
    } else {
      filteredDataByArea = userData.customers.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Filter payments based on date range from the filtered data
    const filteredPayments = filteredDataByArea.flatMap((customer) =>
      customer.payments.filter((payment) => {
        // const paymentDate = new Date(payment.pay_date)
        //   .toISOString()
        //   .split("T")[0];
        return payment.pay_date >= fromdate && payment.pay_date <= todate;
      })
    );

    let filteredUsers;
    if (inpUser === "All") {
      filteredUsers = filteredPayments;
    } else {
      filteredUsers = filteredPayments.filter(
        (payment) => payment.collected_by === inpUser
      );
      // console.log(filteredPayments.map((payment) => payment));
    }
    console.log("test10");

    // Sorting payments
    // filteredPayments.sort((a, b) => {
    //   return new Date(a.pay_date) - new Date(b.pay_date);
    // });
    // filteredUsers.reverse();
    // Set the filtered data to state
    setreports(filteredUsers);
    setSearch(filteredUsers);
    setShowTable(true);
  };
  const checkDuplicate = (payment, index, payments) => {
    console.log("Checking duplicate for payment:", payment);
    return payments.some(
      (p, i) =>
        i !== index &&
        p.customer_name === payment.customer_name &&
        p.device_no === payment.device_no
    );
  };

  const handleSearch = (text) => {
    const searchFilter = search;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setreports(searchFilter);
      return;
    }

    const filteredReports = searchFilter.filter((customer) =>
      Object.values(customer).some((value) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(text.toLowerCase());
      })
    );

    setreports(filteredReports);
    calculateTotalPayments();
  };

  const calculateTotalPayments = () => {
    return reports.reduce((total, payment) => total + payment.amount, 0);
  };
  const totalRow = (
    <TableRow>
      <TableCell colSpan={6} />
      <TableCell
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontWeight: "bolder",
          fontSize: "20px",
        }}
      >
        Total Payments
      </TableCell>
      <TableCell
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontWeight: "bolder",
          fontSize: "20px",
        }}
      >
        {calculateTotalPayments()}
      </TableCell>
      <TableCell colSpan={2} />
    </TableRow>
  );

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      theme: "striped",
      styles: {
        textColor: [0, 0, 0], // White text color for headers
        fontSize: 5, // Font size for the text
        cellPadding: 2, // Padding for each cell
        valign: "middle", // Vertical alignment for all cells
        halign: "center", // Horizontal alignment for all cells
      },
      headStyles: {
        fillColor: [66, 133, 244], // Blue fill color for headers
        textColor: [255, 255, 255],
        fontStyle: "bold", // Bold font style for headers
      },
      bodyStyles: {
        fillColor: false, // No fill color for body
      },
      columnStyles: {
        0: { cellWidth: "auto" }, // Adjust column width automatically
      },
      margin: { top: 20 }, // Set margin for the PDF
    });
    doc.save("customers.pdf");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        minHeight: "100vh",
        padding: "15px 40px",
      }}
    >
      <Typography
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        Payment Report
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* From Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              From Date
            </InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefFromDate }}
            />
          </Box>
          {/* To Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              To Date
            </InputLabel>
            <StyledInputBase
              defaultValue={getCurrentDate()}
              placeholder="Register Date"
              type="date"
              inputProps={{ ref: inputRefToDate }}
            />
          </Box>
        </Box>
        {/* Options */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {/* Report Type
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Type of Report</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="All"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefType,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Paid"}>Paid</MenuItem>
              <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
            </StyledSelect>
          </Box> */}
          {/* Area Wise */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              Area
            </InputLabel>

            <StyledSelect
              displayEmpty
              defaultValue="All"
              sx={{ width: { xs: "120px", sm: "200px" } }}
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefArea,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {userData.areas &&
                userData.areas.map((item) => (
                  <MenuItem value={item.area_name} key={item.area_name}>
                    {item.area_name}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
          {/* UserWise */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel
              sx={{ color: "#fff", display: { xs: "none", sm: "block" } }}
            >
              User
            </InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue={"All"}
              value={inpUser}
              onChange={(e) => setInpUser(e.target.value)}
              sx={{ width: { xs: "120px", sm: "200px" } }}
              inputProps={{
                "aria-label": "Without label",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={userData?.name}>
                {userData?.name?.toUpperCase()}
              </MenuItem>
              {userData.users &&
                userData.users.map((item) => (
                  <MenuItem value={item.name} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            color: "#000",
            backgroundColor: "#fbbd2c",
            "&:hover": {
              color: "#000",
              backgroundColor: "#fbbd2c",
            },
          }}
          onClick={handleGetPayments}
        >
          view
        </Button>
        {showTable ? (
          <>
            <DownloadTableExcel
              filename="payments"
              sheet="payments"
              currentTableRef={tableRef && tableRef.current}
            >
              <Button
                sx={{
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                  "&:hover": {
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                  },
                }}
              >
                Excel
              </Button>
            </DownloadTableExcel>
            <Button
              sx={{
                color: "#000",
                backgroundColor: "#fbbd2c",
                "&:hover": {
                  color: "#000",
                  backgroundColor: "#fbbd2c",
                },
              }}
              onClick={downloadPDF}
            >
              pdf
            </Button>
            <input
              style={{
                backgroundColor: "#fbbd2c",
                borderRadius: "20px",
                width: "200px",
                height: "30px",
                outline: "none",
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="STB No"
            />
            <Typography sx={{ color: "#fbbd2c" }}>
              Count: {reports.length}
            </Typography>
          </>
        ) : (
          ""
        )}
      </Box>
      {/* List */}
      {showTable ? (
        <Box sx={{ overflow: "scroll" }}>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow
                sx={{ backgroundColor: "#fbbd2c", position: "sticky", top: 0 }}
              >
                {columns.map((item) => (
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    key={item}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reports &&
                reports.map((item, index) => (
                  <TableRow
                    key={item._id}
                    sx={{
                      backgroundColor: checkDuplicate(item, index, reports)
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",

                        backgroundColor:
                          userData?.customers.filter((customer) =>
                            customer.payments.some(
                              (payment) => payment._id === item._id
                            )
                          )[0].customer_name !== item.customer_name
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {item.customer_name}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {userData?.customers
                        .filter((customer) =>
                          customer.payments.some(
                            (payment) => payment._id === item._id
                          )
                        )[0]
                        ?.caf_no?.toUpperCase() || ""}
                    </TableCell>

                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {
                        userData?.customers.filter((customer) =>
                          customer.payments.some(
                            (payment) => payment._id === item._id
                          )
                        )[0]?.area
                      }
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {
                        userData?.customers.filter((customer) =>
                          customer.payments.some(
                            (payment) => payment._id === item._id
                          )
                        )[0]?.mobile
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        backgroundColor:
                          userData?.customers.filter((customer) =>
                            customer.payments.some(
                              (payment) => payment._id === item._id
                            )
                          )[0].stb_no !== item.device_no
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {item.device_no}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.pay_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        textAlign: "center",

                        // backgroundColor:
                        //   userData?.customers.filter((customer) =>
                        //     customer.payments.some(
                        //       (payment) => payment._id === item._id
                        //     )
                        //   )[0].cable_plan !== item.amount
                        //     ? "red"
                        //     : "inherit",

                        backgroundColor:
                          item.amount === item.bill_amount ? "inherit" : "red",
                      }}
                    >
                      {item.amount}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.collected_by}
                    </TableCell>
                  </TableRow>
                ))}
              {totalRow}
            </TableBody>
          </Table>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Collections;
