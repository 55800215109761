import PropTypes from "prop-types";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  styled,
  InputLabel,
  InputBase,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import React, { useEffect, useRef, useState, useContext } from "react";
import TablePagination from "@mui/material/TablePagination";
import AuthContext from "../../context/authContext";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const Logs = () => {
  const { userData } = useContext(AuthContext);
  const [searchData, setSearchData] = useState([]);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = React.useState(0);
  const [tabwise, setTabWise] = useState([]);
  const inputRefFromDate = useRef(null);
  const inputRefToDate = useRef(null);
  const [showTable, setShowTable] = useState(false);

  const handleGetPaymentLogs = async () => {
    const fromdate = inputRefFromDate.current.value;
    const todate = inputRefToDate.current.value;

    // Filter payments based on date range from the filtered data
    const filteredPayments = tabwise.filter(
      (payment) =>
        payment.date.split("T")[0] >= fromdate &&
        payment.date.split("T")[0] <= todate
    );
    // Set the filtered data to state

    setSearchData(filteredPayments);
    setReports(filteredPayments);
    setShowTable(true);
  };

  const handleSearch = (text) => {
    const searchfilter = searchData;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setReports(searchfilter);
      return;
    }

    // Filter reports based on the search text
    const filteredReports = searchfilter.filter((item) =>
      item.remarks.toLowerCase().includes(text.toLowerCase())
    );
    // Set the filtered data to state
    setReports(filteredReports);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabWise(userData.logs_payments);
      setShowTable(false);
    } else if (newValue === 1) {
      setTabWise(userData.logs_customers);
      setShowTable(false);
    } else if (newValue === 2) {
      setTabWise(userData.logs_users);
      setShowTable(false);
    } else if (newValue === 3) {
      setTabWise(userData.logs_client);
      setShowTable(false);
    } else {
      setTabWise(userData.logs_payments);
    }
  };

  useEffect(() => {
    if (userData.logs_payments) {
      setTabWise(userData?.logs_payments);
    }
  }, [userData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        color: "#fff",
        margin: "20px",
        minHeight: "100vh",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Typography
        sx={{ textAlign: "center", fontSize: "25px", color: "#fbbd2c" }}
      >
        Logs
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .Mui-selected": {
              color: "#fbbd2c",
              fontWeight: "bold",
              fontSize: "18px",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#fbbd2c",
              color: "#fbbd2c",
            },
            overflow: "scroll",
            overflowX: "auto",
            overflowY: "auto",
            whiteSpace: "nowrap",
            display: "flex",
            flexWrap: "nowrap",
            width: "100%",
          }}
        >
          <Tab label="Payments" {...a11yProps(0)} sx={{ color: "#fbbd2c" }} />
          <Tab label="Customer" {...a11yProps(1)} sx={{ color: "#fbbd2c" }} />
          <Tab label="User" {...a11yProps(2)} sx={{ color: "#fbbd2c" }} />
          <Tab label="Client" {...a11yProps(2)} sx={{ color: "#fbbd2c" }} />
        </Tabs>
      </Box>
      {/* payments */}
      <CustomTabPanel value={value} index={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* From Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>From</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefFromDate }}
              />
            </Box>
            {/* To Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>To Date</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefToDate }}
              />
            </Box>
            {/* Search */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Search</InputLabel>
              <StyledInputBase
                placeholder="Search"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>

            <Button
              onClick={handleGetPaymentLogs}
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                width: "200px",
                height: "40px",
                marginTop: "20px",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
            >
              Get
            </Button>
          </Box>
          {showTable ? (
            <Box sx={{ overflow: "scroll", marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000" }}>S.No</TableCell>
                    <TableCell sx={{ color: "#000" }}>Date</TableCell>
                    <TableCell sx={{ color: "#000" }}>Time</TableCell>
                    <TableCell sx={{ color: "#000" }}>Logs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      // Extracting date and time portions
                      const datePart = item?.date?.split("T")[0];
                      const timePart = item?.date?.split("T")[1].split(".")[0];

                      // Creating a Date object from the time portion
                      const time = new Date(`2024-04-20T${timePart}`);

                      // Extracting hours and minutes
                      const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
                      const minutes = time.getMinutes();

                      // Determine AM/PM
                      const meridiem = time.getHours() >= 12 ? "PM" : "AM";

                      return (
                        <TableRow key={item.log_no}>
                          <TableCell sx={{ color: "#fff" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {datePart}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {hours}:{minutes < 10 ? `0${minutes}` : minutes}{" "}
                            {meridiem}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {item.remarks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {showTable ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 25, 1000]}
              component="div"
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#fbbd2c",
                "& .MuiSvgIcon-root": {
                  // Targeting all SVG icons
                  fill: "#fbbd2c", // Arrow color
                },
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </CustomTabPanel>
      {/* Customers */}
      <CustomTabPanel value={value} index={1}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* From Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>From</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefFromDate }}
              />
            </Box>
            {/* To Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>To Date</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefToDate }}
              />
            </Box>
            {/* Search */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Search</InputLabel>
              <StyledInputBase
                placeholder="Search"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>

            <Button
              onClick={handleGetPaymentLogs}
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                width: "200px",
                height: "40px",
                marginTop: "20px",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
            >
              Get
            </Button>
          </Box>
          {showTable ? (
            <Box sx={{ overflow: "scroll", marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000" }}>S.No</TableCell>
                    <TableCell sx={{ color: "#000" }}>Date</TableCell>
                    <TableCell sx={{ color: "#000" }}>Time</TableCell>
                    <TableCell sx={{ color: "#000" }}>Logs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      // Extracting date and time portions
                      const datePart = item?.date?.split("T")[0];
                      const timePart = item?.date?.split("T")[1].split(".")[0];

                      // Creating a Date object from the time portion
                      const time = new Date(`2024-04-20T${timePart}`);

                      // Extracting hours and minutes
                      const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
                      const minutes = time.getMinutes();

                      // Determine AM/PM
                      const meridiem = time.getHours() >= 12 ? "PM" : "AM";

                      return (
                        <TableRow key={item.log_no}>
                          <TableCell sx={{ color: "#fff" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {datePart}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {hours}:{minutes < 10 ? `0${minutes}` : minutes}{" "}
                            {meridiem}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {item.remarks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {showTable ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 25, 1000]}
              component="div"
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#fbbd2c",
                "& .MuiSvgIcon-root": {
                  // Targeting all SVG icons
                  fill: "#fbbd2c", // Arrow color
                },
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </CustomTabPanel>
      {/* User */}
      <CustomTabPanel value={value} index={2}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* From Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>From</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefFromDate }}
              />
            </Box>
            {/* To Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>To Date</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefToDate }}
              />
            </Box>
            {/* Search */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Search</InputLabel>
              <StyledInputBase
                placeholder="Search"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>

            <Button
              onClick={handleGetPaymentLogs}
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                width: "200px",
                height: "40px",
                marginTop: "20px",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
            >
              Get
            </Button>
          </Box>
          {showTable ? (
            <Box sx={{ overflow: "scroll", marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000" }}>S.No</TableCell>
                    <TableCell sx={{ color: "#000" }}>Date</TableCell>
                    <TableCell sx={{ color: "#000" }}>Time</TableCell>
                    <TableCell sx={{ color: "#000" }}>Logs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      // Extracting date and time portions
                      const datePart = item?.date?.split("T")[0];
                      const timePart = item?.date?.split("T")[1].split(".")[0];

                      // Creating a Date object from the time portion
                      const time = new Date(`2024-04-20T${timePart}`);

                      // Extracting hours and minutes
                      const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
                      const minutes = time.getMinutes();

                      // Determine AM/PM
                      const meridiem = time.getHours() >= 12 ? "PM" : "AM";

                      return (
                        <TableRow key={item.log_no}>
                          <TableCell sx={{ color: "#fff" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {datePart}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {hours}:{minutes < 10 ? `0${minutes}` : minutes}{" "}
                            {meridiem}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {item.remarks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {showTable ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 25, 1000]}
              component="div"
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#fbbd2c",
                "& .MuiSvgIcon-root": {
                  // Targeting all SVG icons
                  fill: "#fbbd2c", // Arrow color
                },
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </CustomTabPanel>
      {/* Client */}
      <CustomTabPanel value={value} index={3}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* From Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>From</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefFromDate }}
              />
            </Box>
            {/* To Date */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>To Date</InputLabel>
              <StyledInputBase
                defaultValue={getCurrentDate()}
                placeholder="Register Date"
                type="date"
                inputProps={{ ref: inputRefToDate }}
              />
            </Box>
            {/* Search */}
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>Search</InputLabel>
              <StyledInputBase
                placeholder="Search"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>

            <Button
              onClick={handleGetPaymentLogs}
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                width: "200px",
                height: "40px",
                marginTop: "20px",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
            >
              Get
            </Button>
          </Box>
          {showTable ? (
            <Box sx={{ overflow: "scroll", marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                    <TableCell sx={{ color: "#000" }}>S.No</TableCell>
                    <TableCell sx={{ color: "#000" }}>Date</TableCell>
                    <TableCell sx={{ color: "#000" }}>Time</TableCell>
                    <TableCell sx={{ color: "#000" }}>Logs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item, index) => {
                      // Extracting date and time portions
                      const datePart = item?.date?.split("T")[0];
                      const timePart = item?.date?.split("T")[1].split(".")[0];

                      // Creating a Date object from the time portion
                      const time = new Date(`2024-04-20T${timePart}`);

                      // Extracting hours and minutes
                      const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
                      const minutes = time.getMinutes();

                      // Determine AM/PM
                      const meridiem = time.getHours() >= 12 ? "PM" : "AM";

                      return (
                        <TableRow key={item.log_no}>
                          <TableCell sx={{ color: "#fff" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {datePart}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {hours}:{minutes < 10 ? `0${minutes}` : minutes}{" "}
                            {meridiem}
                          </TableCell>
                          <TableCell sx={{ color: "#fff" }}>
                            {item.remarks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {showTable ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 25, 100]}
              component="div"
              count={(reports && reports.length) || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                color: "#fbbd2c",
                "& .MuiSvgIcon-root": {
                  // Targeting all SVG icons
                  fill: "#fbbd2c", // Arrow color
                },
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </CustomTabPanel>
    </Box>
  );
};
export default Logs;
