import React from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.log(error, errorInfo.componentStack);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          {/* Render fallback UI */}
          {this.props.fallback}
          <Navigate to="/" replace={true} />
          {window.location.reload()}
          {toast.error("Something Went Wrong!")}
        </>
      );
    }

    return this.props.children;
  }
}
