import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Paper,
  InputBase,
  Button,
  Modal,
} from "@mui/material";
import { Geolocation } from "@capacitor/geolocation";
import React, { useEffect, useState, useContext, useCallback } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CallIcon from "@mui/icons-material/Call";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import NearMeIcon from "@mui/icons-material/NearMe";
import { getRoles, user_name } from "../context/RequireAuth";
import CustomerPay from "../components/modals/customerPay";
import AuthContext from "../context/authContext";
import axios from "../api/axios";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { toast } from "react-hot-toast";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#fff",
      backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
    },
  },
};
const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 200,
});
const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  height: 150,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
};

const PaymentList = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  // const [userData, setUserData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportLength, setReportlength] = useState(0);
  const [clientArea, setClientArea] = useState([]);
  const [openPay, setOpenPay] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [custViewData, setCustViewData] = useState([]);
  const [selectedArea, setSelectedArea] = useState("All");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [custID, setCustId] = useState("");

  const handleClosePay = () => {
    setOpenPay(false);
    setRefresh(true);
  };

  const handleAreaFilter = (area) => {
    let filteredDataByArea = [];
    setSelectedArea(area);

    if (area === "All") {
      filteredDataByArea = areaData;
    } else {
      filteredDataByArea = areaData.filter((customer) =>
        area.includes(customer.area)
      );
    }
    setReports(filteredDataByArea);
    setReportlength(filteredDataByArea.length);
    setSearchData(filteredDataByArea);
  };

  const handleSearch = (text) => {
    const searchfilter = searchData;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setReports(searchfilter);
      setReportlength(searchfilter.length);
      return;
    }

    const filteredReports = searchfilter.filter((customer) =>
      Object.values(customer).some((value) => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(text.toLowerCase());
      })
    );

    setReports(filteredReports);
    setReportlength(filteredReports.length);
  };

  // User Data Fetch
  const updateUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  // Location for App
  // const printCurrentPosition = async () => {
  //   const position = await Geolocation.getCurrentPosition();
  //   setUserLocation({
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //   });
  //   console.log("Current position:", position.coords);
  // };

  useEffect(() => {
    if (userData) {
      const customers = userData?.customers?.filter(
        (cust) => cust.payment_status === "Unpaid"
      );

      const findIfUser = userData?.users?.find(
        (item) => item.name === user_name()
      );

      if (getRoles() === "user") {
        const findUserArea = findIfUser?.area;
        // const findIfUserArea = findUserArea?.includes("All");

        const userArea = userData?.users?.filter(
          (item) => item.name === user_name()
        );
        const filterd = customers?.filter((item) =>
          findUserArea.includes(item.area)
        );
        if (selectedArea === "All") {
          setReports(filterd);
          setReportlength(filterd?.length || 0);
          setSearchData(filterd);
          setAreaData(filterd);
        } else {
          setReports(filterd?.filter((item) => selectedArea === item.area));
          setReportlength(
            filterd?.filter((item) => selectedArea === item.area).length || 0
          );
          setSearchData(filterd?.filter((item) => selectedArea === item.area));
          setAreaData(filterd);
        }
        setClientArea(findUserArea);
      } else {
        if (selectedArea === "All") {
          setReports(customers);
          setReportlength(customers?.length || 0);
          setSearchData(customers);
          setAreaData(customers);
        } else {
          setReports(customers?.filter((item) => selectedArea === item.area));
          setReportlength(
            customers?.filter((item) => selectedArea === item.area).length || 0
          );
          setSearchData(
            customers?.filter((item) => selectedArea === item.area)
          );
          setAreaData(customers);
        }
        setClientArea(userData?.areas);
      }
      setRefresh(false);
    }
    // printCurrentPosition();

    // if (selectedArea !== "") {
    //   handleAreaFilter(selectedArea);
    // }
    // handleAreaFilter(selectedArea);

    updateUserLocation();
    const locationInterval = setInterval(updateUserLocation, 10000); // Update location every 10 seconds

    return () => clearInterval(locationInterval);
  }, [userData]);

  const handlePayment = (id) => {
    const filters = areaData.filter((item) => item?._id === id);
    setCustViewData(filters);
    setOpenPay(true);
  };

  const handleOpenConfirmModal = (id) => {
    setOpenConfirmModal(true);
    setCustId(id);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setRefresh(true);
    // refreshUserData();
  };

  const handleNavigation = (lat, long) => {
    if (userLocation) {
      const { lat: userLat, lng: userLng } = userLocation;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${lat},${long}&travelmode=driving`;
      window.open(url, "_blank");
    } else {
      toast.error("User location not available");
    }
  };
  // const refreshUserData = async () => {
  //   try {
  //     const response = await axios.get("/userdata", {
  //       headers: {
  //         "x-auth-token": localStorage.getItem("token"),
  //         lcono: localStorage.getItem("lcono"),
  //       },
  //     });
  //     setUserData(response.data.clientData);
  //     const customers = response.data.clientData.customers?.filter(
  //       (cust) => cust.payment_status === "Unpaid"
  //     );
  //     console.log(customers);
  //     const findIfUser = response.data.clientData.users.find(
  //       (item) => item.name === user_name()
  //     );

  //     if (getRoles() === "user") {
  //       const findUserArea = findIfUser?.area;
  //       // const findIfUserArea = findUserArea?.includes("All");
  //       console.log(findUserArea);
  //       const userArea = response.data.clientData?.users?.filter(
  //         (item) => item.name === user_name()
  //       );
  //       const filterd = customers?.filter((item) =>
  //         findUserArea.includes(item.area)
  //       );
  //       setReports(filterd);
  //       setReportlength(filterd?.length || 0);
  //       setSearchData(filterd);
  //       setAreaData(filterd);
  //       setClientArea(findUserArea);
  //     } else {
  //       setReports(customers);
  //       setReportlength(customers?.length || 0);
  //       setSearchData(customers);
  //       setAreaData(customers);
  //       setClientArea(response.data?.clientData?.areas);
  //     }
  //     setRefresh(false);

  //     // printCurrentPosition();

  //     if (selectedArea !== "") {
  //       handleAreaFilter(selectedArea);
  //     }
  //     // handleAreaFilter(selectedArea);

  //     updateUserLocation();
  //     const locationInterval = setInterval(updateUserLocation, 10000); // Update location every 10 seconds

  //     return () => clearInterval(locationInterval);
  //   } catch (error) {
  //     console.error("Error refreshing user data:", error);
  //   }
  // };
  // useEffect(() => {
  //   refreshUserData();
  //   // handleAreaFilter("All");
  // }, []);
  const handleConfirmAssign = async (e) => {
    if (!userLocation) {
      toast.error("Kindly Enable Location");
    } else {
      try {
        const lat = userLocation.lat;
        const long = userLocation.lng;
        const res = await axios.post(
          "/assignlocation",
          { lat, long, custID },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: localStorage.getItem("lcono"),
            },
          }
        );
        if (res.status === 200) {
          handleCloseConfirmModal();
          toast.success("Customer Location Added");
          setRefresh(true);
        }
      } catch (error) {
        toast.error("Error Fetching Location");
      }
    }
  };

  const renderCustomerRow = useCallback(
    ({ index, key, parent, style }) => {
      const item = reports && reports[index];
      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <Box style={style}>
            <Box
              sx={{
                border: "2px solid #fbbd2c",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              key={item?._id}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* customer Data */}
                <Box sx={{ padding: "10px" }}>
                  <Typography sx={{ color: "#fff" }}>
                    Name: {item?.customer_name?.toUpperCase()}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    Phone: {item?.mobile}
                  </Typography>

                  <Typography sx={{ color: "#fff" }}>
                    Area: {item?.area}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    STB: {item?.stb_no}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    {" "}
                    Last:{" "}
                    {item?.payments?.length > 0
                      ? item?.payments?.reverse()[0].pay_date
                      : "N/A"}
                    | {item?.caf_no?.toUpperCase()}
                  </Typography>
                </Box>
                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "10px 0px",
                  }}
                >
                  {item?.payment_status === "Paid" ? (
                    <Button>
                      <DoneIcon
                        sx={{
                          color: "green",
                          fontWeight: "bolder",
                          border: "1px solid green",
                          borderRadius: "50px",
                        }}
                      />{" "}
                    </Button>
                  ) : (
                    <Button>
                      <ClearIcon
                        sx={{
                          color: "red",
                          fontWeight: "bolder",
                          border: "1px solid red",
                          borderRadius: "50px",
                        }}
                      />
                    </Button>
                  )}
                  {item?.mobile !== "" && item?.mobile !== 0 ? (
                    <Button component="a" href={`tel:${item?.mobile}`}>
                      <CallIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  ) : (
                    ""
                  )}

                  {/* {(item.lat !== "" && item.long !== "") || */}
                  {/* (!item.lat && !item.long) ? ( */}
                  {/* <Button */}
                  {/* component="a" */}
                  {/* target="_blank" */}
                  {/* href={`https://www.google.com/maps/dir/${userLocation?.lat},${userLocation?.lng}/${item.lat},${item.long}/@16z`} */}
                  {/* > */}
                  {/* <NearMeIcon */}
                  {/* sx={{ color: "#fbbd2c", fontWeight: "bolder" }} */}
                  {/* //   /> */}
                  {/* // </Button> */}
                  {/* // ) : ( */}
                  {/* // "" */}
                  {/* // )} */}
                  {(item?.lat === "" && item?.long === "") ||
                  (!item?.lat && !item?.long) ? (
                    <Button onClick={() => handleOpenConfirmModal(item?._id)}>
                      <MyLocationIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleNavigation(item?.lat, item?.long)}
                    >
                      <NearMeIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#fbbd2c",
                  padding: "0px 10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "80px",
                    textAlign: "center",
                  }}
                >
                  <Typography>Plan</Typography>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    {item?.cable_plan}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "80px",
                    textAlign: "center",
                  }}
                >
                  <Typography>Bal</Typography>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    {item?.cable_balance}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "80px",
                    textAlign: "center",
                  }}
                >
                  <Button onClick={() => handlePayment(item?._id)}>
                    <CurrencyRupeeIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                        fontWeight: "bolder",
                      }}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CellMeasurer>
      );
    },
    [reports, userLocation]
  );

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fbbd2c",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Payment list ({reports?.length})
        </Typography>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "15px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                onChange={(e) => {
                  handleAreaFilter(e.target.value);
                }}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
                defaultValue={"All"}
                value={selectedArea}
                sx={{
                  backgroundColor: "#fbbd2c",
                  width: "100px",
                  height: "50px",
                  borderColor: "#fbbd2c",
                }}
              >
                <MenuItem value={"All"}>
                  <ListItemText primary={"All"} />
                </MenuItem>
                {clientArea?.map((name) =>
                  getRoles() === "user" ? (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ) : (
                    <MenuItem key={name.area_name} value={name.area_name}>
                      <ListItemText primary={name.area_name} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fbbd2c",
                borderRadius: "30px",
                width: "70%",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                  width: "100%",
                }}
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                inputProps={{ "aria-label": "Search" }}
              />
            </Paper>
          </Box>
        </Box>
        {/* Lists */}
        <Box
          // sx={{
          //   margin: "5px 10px",
          //   display: "flex",
          //   flexDirection: "column",
          //   gap: "20px",
          // }}
          sx={{ height: "70vh", margin: "0 10px" }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                rowCount={reportLength}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={renderCustomerRow}
              />
            )}
          </AutoSizer>
        </Box>
      </Box>
      <CustomerPay
        openPay={openPay}
        handleClosePay={handleClosePay}
        custData={custViewData[0]}
      />
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box sx={modalStyles}>
          <Box>
            <Typography>Confirm Location?</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleConfirmAssign}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PaymentList;
