import { Box } from "@mui/material";
import React from "react";

const Office = () => {
  return (
    <Box
      sx={{
        display: "flex",
        color: "#fff",
        justifyContent: "center",
        alignItems: "center",
        height: "30%",
        fontSize: "30px",
      }}
    >
      <Box>Under Construction</Box>
    </Box>
  );
};

export default Office;
