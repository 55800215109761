import {
  Box,
  Typography,
  TextField,
  Stack,
  Autocomplete,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
  CircularProgress,
} from "@mui/material";
import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import AuthContext from "../../context/authContext";

const columns = [
  "S.No",
  "Name",
  "STB No",
  "Bill Date",
  "Bill Amount",
  "Payment",
  "Pay Date",
  "Remarks",
  "Collected By",
];

const History = () => {
  const { userData } = useContext(AuthContext);

  const [customerData, setCustomerData] = useState([]);
  const [showCustTable, setShowCustTable] = useState(false);
  const [showStbTable, setShowStbTable] = useState(false);
  const [searchCust, setSearchCust] = useState("");
  const [searchStbNo, setSearchStbNo] = useState("");
  const [stbData, setStbData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchCust = (e, selectedValue) => {
    setShowStbTable(false);
    if (selectedValue !== null) {
      e.preventDefault();

      if (!selectedValue) {
        toast.error("Kindly fill CafNo");
        return;
      }
      const searchcaf = selectedValue.split(" ")[0];
      const searchcaf1 = searchcaf.toLowerCase();
      const filteredUsers = userData?.customers?.filter(
        (cust) => cust.caf_no.toLowerCase() === searchcaf1
      );

      if (filteredUsers.length > 0) {
        filteredUsers[0].payments.reverse();
        setCustomerData(filteredUsers[0]);
        setShowCustTable(true);
      } else {
        setCustomerData([]);
        toast.error("No Customers Found");
      }
    }
  };

  const handleSearchStbNo = (e, selectedValue) => {
    setShowCustTable(false);
    if (selectedValue !== null) {
      e.preventDefault();
      if (!selectedValue) {
        toast.error("Kindly fill STB No");
        return;
      }
      const searchstb = selectedValue.split(" ")[0];
      const searchstb1 = searchstb?.toLowerCase();

      const stbarray = [];
      userData.customers.forEach((ele) =>
        ele.payments.forEach((ite) => {
          if (ite.device_no?.toLowerCase() === searchstb1) {
            stbarray.push(ite);
          }
        })
      );
      setStbData(stbarray);

      setShowStbTable(true);
    }
  };

  const getTotal = (field) => {
    return (
      customerData.payments?.reduce(
        (acc, curr) => acc + (curr[field] || 0),
        0
      ) || 0
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        marginTop: "40px",
        borderRadius: "30px",
      }}
    >
      <Typography
        sx={{ color: "#fbbd2c", textAlign: "center", fontSize: "25px" }}
      >
        Customer History
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
            PaperComponent={({ children }) => (
              <Paper
                style={{
                  fontSize: "18px",
                  // Change the background color
                  backgroundColor: "#24243e",
                  color: "#fff",
                }}
              >
                {children}
              </Paper>
            )}
            ListboxProps={{
              style: {
                maxHeight: "200px", // Adjust the height as needed (5 rows)
              },
            }}
            onChange={(e, value) => {
              setSearchCust(value);
              handleSearchCust(e, value);
              setSearchStbNo("");
            }}
            value={searchCust}
            freeSolo
            defaultValue={null}
            disableClearable
            options={
              (userData &&
                userData.customers &&
                userData.customers
                  .filter((item) => item.customer_status === "Active")
                  .map(
                    (option) =>
                      option.caf_no.toUpperCase() +
                      " " +
                      option.mobile +
                      " " +
                      option.customer_name
                  )) ||
              []
            }
            filterOptions={(options, { inputValue }) =>
              options
                .filter((option) =>
                  option.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 7)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Customers"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
            PaperComponent={({ children }) => (
              <Paper
                style={{
                  fontSize: "18px",
                  // backgroundImage:
                  //   "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
                  backgroundColor: "#24243e",
                  color: "#fff",
                }}
              >
                {children}
              </Paper>
            )}
            ListboxProps={{
              style: {
                maxHeight: "200px", // Adjust the height as needed (5 rows)
              },
            }}
            onChange={(e, value) => {
              setSearchCust("");
              handleSearchStbNo(e, value);
              setSearchStbNo(value);
            }}
            value={searchStbNo}
            freeSolo
            defaultValue={null}
            disableClearable
            options={
              (userData &&
                userData.customers &&
                userData.customers
                  .filter((item) => item.customer_status === "Active")
                  .map(
                    (option) =>
                      option.stb_no.toUpperCase() + " " + option.customer_name
                  )) ||
              []
            }
            filterOptions={(options, { inputValue }) =>
              options
                .filter((option) =>
                  option.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 7)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search STB NO"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Stack>
      </Box>

      {showCustTable && (
        <Box
          sx={{
            overflow: "scroll",
            maxHeight: "100vh",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                {columns.map((item) => (
                  <TableCell
                    sx={{
                      color: "#000",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                    key={item}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData &&
                customerData.payments &&
                customerData.payments.map((pays, index) => (
                  <TableRow key={pays._id}>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {customerData.customer_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.device_no}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.bill_date && pays.bill_date.split("T")[0]}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.bill_amount && pays.bill_amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.pay_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.remarks}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.collected_by}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {getTotal("bill_amount")}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {getTotal("amount")}
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}

      {showStbTable && (
        <Box
          sx={{
            overflow: "scroll",
            maxHeight: "100vh",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                {columns.map((item) => (
                  <TableCell
                    sx={{
                      color: "#000",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                    key={item}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stbData &&
                stbData.map((pays, index) => (
                  <TableRow key={pays._id}>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                      {pays.customer_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.device_no}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.bill_date && pays.bill_date.split("T")[0]}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.bill_amount && pays.bill_amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.pay_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.remarks && pays.remarks}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {pays.collected_by}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {getTotal("bill_amount")}
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {getTotal("amount")}
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default History;
