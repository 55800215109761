import {
  Box,
  Typography,
  InputBase,
  Button,
  InputLabel,
  styled,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const Area = () => {
  const inputRefAreaName = useRef(null);
  const inputRefAreaCode = useRef(null);
  const inputRefNewAreaName = useRef(null);
  const inputRefNewAreaCode = useRef(null);

  return (
    <Box sx={{ height: "100vh", padding: { xs: "5px 10px", sm: "15px 40px" } }}>
      <Typography
        sx={{ textAlign: "center", fontSize: "20px", color: "#fbbd2c" }}
      >
        Add Area
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          marginTop: "30px",
        }}
      >
        {/* Area Name */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Area Name</InputLabel>
          <StyledInputBase
            style={{
              width: "200px",
              backgroundColor: "#fff",
              margin: "10px 0px",
              borderRadius: "20px",
            }}
            placeholder="Area Name"
            type="text"
            inputProps={{ ref: inputRefAreaName }}
          />
        </Box>
        {/* Area Code */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Area Code</InputLabel>
          <StyledInputBase
            style={{
              width: "200px",
              backgroundColor: "#fff",
              margin: "10px 0px",
              borderRadius: "20px",
            }}
            placeholder="Area Code"
            type="text"
            inputProps={{ ref: inputRefAreaCode }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{
              backgroundColor: "#fbbd2c",
              color: "#000",
              "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
            }}
          >
            <AddIcon /> Add Area
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px", overflow: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
              <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                S.No
              </TableCell>
              <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>
                Area Name
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Area Code
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Customers
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Convert
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                1
              </TableCell>
              <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                Chinnampalayam
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                CNP
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                235
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <InputBase
                  sx={{
                    ml: 2,
                    flex: 1,
                    color: "#000",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "0px 10px",
                    width: "80px",
                  }}
                  placeholder="New Area Name"
                  inputProps={{
                    "aria-label": "Search",
                    ref: inputRefNewAreaName,
                  }}
                />
                <InputBase
                  sx={{
                    ml: 2,
                    flex: 1,
                    color: "#000",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "0px 10px",
                    width: "80px",
                  }}
                  placeholder="New Area Code"
                  inputProps={{
                    "aria-label": "Search",
                    ref: inputRefNewAreaCode,
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  gap: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                <Button
                  sx={{
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                    margin: "0px 10px",
                    "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
                  }}
                >
                  {" "}
                  Convert
                </Button>
                <Button
                  sx={{
                    color: "#000",
                    backgroundColor: "#fbbd2c",
                    margin: "0px 10px",
                    "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
                  }}
                >
                  {" "}
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Area;
