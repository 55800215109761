import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputBase,
  Button,
  Modal,
  styled,
  InputLabel,
} from "@mui/material";
import toast from "react-hot-toast";
import axios from "../../api/axios";
import AuthContext from "../../context/authContext";

const custPay_URL = "/handlecustpay";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 240,
  border: "2px solid #fbbd2c",
  boxShadow: 24,
  p: 4,
  backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
};
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};
const CustomerPay = ({ openPay, handleClosePay, custData }) => {
  const { setRefresh } = useContext(AuthContext);
  const [data, setData] = useState(custData);
  const [amount, setAmount] = useState(0); // [setAmount]
  const inputRefPay = useRef(0);
  const inputRefTypePay = useRef(null);
  const inputRefPayDate = useRef(null);
  const handleCustomerPayment = async (caf) => {
    if (inputRefPay.current.value === 0) {
      toast.error("Type Amount");
    }
    if (
      inputRefPay.current.value.length >= 2 &&
      inputRefPay.current.value.length <= 4
    ) {
      try {
        const pay = await axios.post(
          custPay_URL,
          {
            caf_no: caf,
            amount: inputRefPay.current.value,
            payType: inputRefTypePay.current.value,
            payDate: inputRefPayDate.current.value,
            collected_by: localStorage.getItem("user_name"),
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: localStorage.getItem("lcono"),
              user_name: localStorage.getItem("username"),
            },
          }
        );
        if (pay.status === 200) {
          toast.success("Payment Successful");
          setAmount(0);
          setRefresh(true);
          handleClosePay();
        } else {
          toast.error("Payment Error");
        }
      } catch (error) {}
    } else {
      toast.error("Amount entered is Wrong!");
    }
  };
  useEffect(() => {
    if (custData) {
      setData(custData);
      setAmount(custData.cable_plan);
    }
  }, [custData]);

  return (
    <Modal
      open={openPay}
      onClose={handleClosePay}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box>
          <Typography
            sx={{ textAlign: "center", color: "#fff", fontSize: "20px" }}
          >
            {data?.customer_name}
          </Typography>

          {/* Cust Details */}
          <Box
            sx={{
              textAlign: "center",
              color: "#fff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>
              Plan : {data?.cable_plan}
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              Bal : {data?.cable_balance}
            </Typography>
          </Box>
          {/* Date */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Date</InputLabel>
            <StyledInputBase
              placeholder="Register Date"
              type="date"
              inputProps={{
                ref: inputRefPayDate,
                defaultValue: getCurrentDate(),
              }}
            />
          </Box>
          {/* Plan */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Amount</InputLabel>
            <StyledInputBase
              placeholder="plan"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              inputProps={{
                ref: inputRefPay,
              }}
            />
          </Box>
          {/* Payment Type */}
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
              margin: "10px 10px",
            }}
          >
            <InputLabel sx={{ color: "#fff" }}>Payment Type</InputLabel>
            <StyledSelect
              displayEmpty
              defaultValue="cash"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefTypePay,
              }}
            >
              <MenuItem value={"cash"}>Cash</MenuItem>
              <MenuItem value={"upi"}>UPI</MenuItem>
              <MenuItem value={"neft"}>Neft</MenuItem>
              <MenuItem value={"shift_charge"}>Shifting Charge</MenuItem>
            </StyledSelect>
          </Box>
          {/* Buttons */}
          <Box
            sx={{
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                },
              }}
              onClick={handleClosePay}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                },
              }}
              onClick={() => handleCustomerPayment(data?.caf_no)}
            >
              Pay
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerPay;
